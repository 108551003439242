@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.assistantHome {
    & .registrationDesk {
        @include formStyles;
        & .registrationForm {
            max-width: 400px;
            width: 100%;
            margin: 100px auto 50px;
        }
    }
    /*& .assistantHomeTab {
        & li:last-child {
            display: none;
        }
    }*/

    & .bottomElement{
        position:fixed;
        @include flex(row,center,center);
        height:50px;
        width:100%;
        bottom:0;
        background-color: $nav-background;
    }
    .invalid {
        border: 2px solid #ff7a7a;
    }
    .field-error {
        display: none;
        font-size: 0.85em;
        align-self: flex-start;
        position: relative;
        margin: 3px 0 0 0;
        color: #ff7a7a;
    }
    .form-element.checkbox {
        flex-flow: row !important;
        justify-content: flex-start !important;

        & #sendSMS {
            width: 20px;
            height: 20px;
        }
        label {
            padding: 4px 0 0 4px;
        }
    }

    .clinicQRCode {
        margin: 50px auto;
        text-align: center;
    }
}
