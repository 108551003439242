@import './../../styles/mixinflex';
@import './../../styles/variables';

.addModuleContainer {
    @include flex(column,flex-start,flex-start);
    position: relative;
    .moduleContainerTab {
        width: 100%;
        margin: 0 auto;
    }
    & .editorComponent {
        width: 100%;
        margin: 40px 0;
        
        @include flex(column,center,space-between);
        & .moduleContentContainer {
            width: 800px;
            overflow-y: auto;
            background-color: $nav-background;
            border-radius: $card-borderradius;
            padding: 25px;
            & > * {
                margin: 0 0 25px 0;
            }
        }
        & .modulesBtn {
            width: 100%;
            background-color: $nav-background;
            margin: 50px 0 0;
            position: fixed;
            bottom: 0;
            @include flex(row,space-around,center);

            & .addButtons {
                margin:0 0 0 20px;
                padding: 10px;
                @include flex(row,center,center);
                & .addContentBlock-btn {
                    margin: 0 10px 0 0;
                }
                & .addContentBlock-btn, & .addMediaBlock-btn {
                    background-color: $nav-background;
                    border: 1px solid $nav-background;
                    padding: 10px 15px;
                    cursor: pointer;
                    color: $primary-color;
                    @include flex(row,flex-start,center);
                    &:hover {
                        border: 1px solid $primary-color;
                    }
                    & .add-image {
                        width: 22px;
                        height: 22px;
                        padding: 0 10px 0 0;
                        font-size: $small-font;
                    }
                }
            }
            & .contentSaveBtnContainer{
                width:fit-content;
                margin:0 20px 0 0;
         }
        }    
    }
}    