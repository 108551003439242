@import './../../styles/mixinflex';
@import './../../styles/variables';

.introduction {
    max-width: 450px;
    height: 410px;
    border: 1px solid $border-color;
    @include flex(column,center,flex-start);
    margin: 120px auto 0;
    & .login-logo {
        width:70px;
        padding: 80px 0 20px;
        & .logo_image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .form-element {
        @include flex(column,flex-start,flex-start);
        & label {
            font-size: $small-font;
            font-weight: $font-weight-regular;
            color: $text-dark-color;
            padding: 0 0 8px 0;
        }
        & input{
            width: 350px;
            height: 40px;
            padding: 0 0 0 15px;
            border: 1px solid $border-color;
            font-size: $medium-font; 
        }
        & .invalid {
            border-color:#ff7a7a;
        }
        & .field-error {
            display: none;
            font-size: 0.85em;
            align-self: flex-start;
            position: relative;
            margin: 3px 0 0 0;
            color: #ff7a7a;
        }
    }
    & .getOtp-btn {
        width: 100%;
        background-color: $primary-color;
        color: $white-color;
        font-size: $small-font;
        font-weight: $font-weight-medium;
        padding: 15px 0;
        text-align: center;
        margin: 15px 0 0 0;
    }
}

@media (max-width: 480px) {

    .introduction {
        width: 380px;
        border: none;

        & .form-element {
            & input {
                width: 320px;
            }
        }
        & .getOtp-btn {
            width: 100%;
        }
    }
}

@media (max-width: 390px) {

    .introduction {
        width: 310px;

        & .form-element {
            & input {
                width: 270px;
            }
        }
        & .getOtp-btn {
            width: 100%;
        }
    }
}
