@import './../../../styles/mixinflex'; 
@import './../../../styles/variables';

.assistant_page_container {

    max-width: 540px;
    width:100%;
    margin:0 auto;
    & .title-section {
        width:100%;
        padding: 30px 15px 20px; 
        @include flex(row,space-between,center);
         & .title {
            font-size: $small-font;
            font-weight: $font-weight-medium;
            color: $text-dark-color;
         }
         & .add-btn {
            border:1px solid $white-color;
            cursor:pointer;
            color: white;
            background-color: $hover-color;
             &:hover  {
                border:1px solid $border-color;
             }
         }
     }
    & .assistant_content {
        width:100%;
        @include flex(column,center,center);
    }
    @media screen and (max-width:580px){
        width:95%;
        @include flex(column,center,center);
    }
}





.profileContainer{
    width:350px;
    .closeBtn_container{
        border:1px solid $white-color;
        margin:0;
        padding:0;
        width:fit-content;
        cursor:pointer;
         &:hover  {
            border:1px solid $border-color;
         }
    }
    & .profileHeader{
        width:100%;
        @include flex(row,space-between,center);
    }
    .AssistantFormContainer{
        padding:30px 0 !important;
        @include formStyles;
       
    }
    @media screen and (max-width:480px){
        width:auto;
        padding:0 16px;
        & .profileHeader{
            padding:30px 0;
            @include flex(row,space-between,center);
        }
        .AssistantFormContainer{
            padding:0 !important;
        }
      }  
}