@import './../../styles/mixinflex';
@import './../../styles/variables';

.programForm {
    @include flex(column,flex-start,flex-start);
    margin: 40px auto 0;
    & .addProgramForm {
        width: 600px;
        background-color: $white-color;
        border: 1px solid $border-color;
        border-radius: $card-borderradius;
        padding: 30px;
        margin: 0 auto 25px;
        & .form-element {
            @include flex(column,flex-start,flex-start);
            & label {
                font-size: $small-font;
                font-weight: 400;
                color: $text-dark-color;
            }
            & #program-name {
                width: 90%;
                height: 40px;
                margin: 10px 0 20px 0;
                padding: 0 0 0 15px;
                border: 1px solid $border-color;
            }
            & #program-description {
                width: 90%;
                height: 100px;
                margin: 10px 0 30px 0;
                padding: 10px 15px;
                border: 1px solid $border-color;
            }
            & .duration-fields {
                margin: 10px 0 0 0;
                @include flex(row,flex-start,flex-start);
                & #program-duration {
                    width: 50px;
                    height: 30px;
                    padding: 0 0 0 15px;
                    margin: 0 15px 0 0;
                    border: 1px solid $border-color;
                }
                & #select-duration {
                    width: 140px;
                    height: 30px;
                    padding: 5px 10px;
                    border: 1px solid $border-color;
                    background-color: $white-color;
                    & option {
                        font-size: $small-font;
                        padding: 6px;
                        color: $text-light-color;
                        &:hover {
                            background-color: $hover-color;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
    & .next-btn {
        text-decoration: none;
        color: $white-color;
        background-color: $primary-color;
        padding: 6px 12px;
        border: $button-border;
        border-radius: $button-borderradius;
        margin-left: auto;
    }
    & .editProgramSave-button {
        width:100%;
        margin: 20px 0 0 0;
        @include flex(row,flex-end,center);    }   
}