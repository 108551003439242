@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.patientRow {
    width: 95%;
    max-width: 840px;
    margin: 0;
    cursor: pointer;
    & .patientRowDetails {
        border: 1px solid $border-color;
        border-radius:  $card-borderradius;
        padding: 25px 30px;
        margin: 0 0 25px 0;
        @include flex(column,flex-start,center);
        & .patientrow1 {
            width: 100%;
            @include flex(row,space-between,center);
            & .patientName {
                font-size: $large-font;
                font-weight: $font-weight-semibold;
                color: $text-dark-color;
                margin: 0;
            }
            & .patientStatus {
                font-size: $small-font;
                font-weight: $font-weight-medium;
                color: $text-success;
                background-color: $bg-success;
                border-radius: $statusBtn-borderradius;
                padding: 4px 8px;
                margin: 0;
                
                @include flex(row,flex-start,center);
                & .activeDot {
                    width: 8px;
                    height: 8px;
                    background-color: $text-success;
                    border-radius: $statusBtn-borderradius;
                    margin: 0 5px 0 0;
                }
                & .newDot {
                    width: 8px;
                    height: 8px;
                    background-color: $primary-color;
                    border-radius: $statusBtn-borderradius;
                    margin: 0 5px 0 0;
                }
            }
            & .New {
                color: $primary-color;
                background-color: $hover-color;
            
            }
        }
        & .patientrow2 {
            width: 100%;
            @include flex(row,flex-start,flex-start);
            flex-wrap: nowrap;
            padding: 15px 0 0 0;
            & .Detail {
                @include flex(column,space-between,center);
                width:100%;
                margin:0 20px 0 0;
                .title {
                    font-size: $small-font;
                    font-weight: $font-weight-regular;
                    color: $text-light-color;
                    margin: 0;
                    padding: 0 15px 0 0;
                }
               .value {
                    font-size: $medium-font;
                    font-weight: $font-weight-regular;
                    color: $text-dark-color;
                    margin: 0;
                }
            }
        }
    }

    @media (max-width:650px){
        & .patientRowDetails{
            & .patientrow1
            {
                & .patientName{
                    font-size:$medium-font-2;
                }
            }
            & .patientrow2{
                @include flex(column,space-between,center);  
            }
            & .patientrow2 {
               & .Detail{
                    @include flex(row,space-between,center);
                    margin:5px 0;
                    flex-wrap:nowrap;
                    & .title{
                        font-size:$medium-font;
                    }
                   }
            }
        }
        
    }
}
