@import './../../styles/mixinflex';
@import './../../styles/variables';

.navigation {
    background-color: $nav-background;
    & .navigation-bar {
        padding: 8px 0;
        margin:0 auto;
        & .logo_container{
            cursor:pointer;
        }
        @include flex(row,space-between,center);
        & .nav-item {
            margin:0;
            padding:0;
            & .nav-links {
                margin:0;
                list-style-type: none;
                @include flex(row,flex-start,center);
                & .nav-items {
                    position: relative;
                    & a {
                        font-family: $font-family;
                        font-size: $small-font;
                        font-weight: $font-weight-medium;
                        color: $text-light-color;
                        padding: 16px 20px;
                        margin: 0;
                        cursor: pointer;
                        &:hover {
                            color: $primary-color;
                        }
                    }
                    & .profile {
                        margin:0 0 0 10px;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        & img {
                            object-fit: contain;
                        }
                    }
                    & .select-option {
                        @include dropdown;
                        top: 40px;
                        z-index:5;
                        left:0;
                    }
                    & .select-option-right {
                        right:0;
                        left:auto;
                    }
                    &:last-child{
                        & a{
                            padding: 16px 0px;
                        }
                    }
                    & .active-item {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    
    @media (min-width:768px) {
        
        & .navigation-bar {
            padding: 8px 22px;
            max-width: 1368px;
        }
    }

    @media (max-width:767px) {
        
        & .navigation-bar {
            padding: 8px 16px;
        }
    }

    @media screen and (max-width: 480px) {
        
        .nav-item {
            & .hamburger {
                padding: 5px;
                border: 1px solid $nav-background;
                &:hover {
                    border: 1px solid $border-color;
                    
                }
                
            }
        }
    }
}

.navItems_div {
    & .navitems_closeBtn_container {
        height:50px;
        padding:8px 16px;
        @include flex(row,flex-end,center);
    }
    & .navItems {
        & .navigation_navItems, & .account_navItems {
            list-style-type: none;
            padding: 0 0 0 16px;
            margin: 0 0 30px 0;

            & .navigation_navItems_Title, & .account_navItems_Title {
                font-size: $larger-font;
                font-weight: $font-weight-semibold;
                color: $text-dark-color;
                margin: 0 0 10px 0;
            }

            & .nav-items {
                margin: 0;
                & a {
                    font-size: $large-font;
                    font-weight: $font-weight-medium;
                    color: $text-light-color;
                    padding: 10px 0;
                    display:inline-block;
                }
            }
        }
        & .account_navItems {
            & li {
                padding: 10px 0;
                font-size: $large-font;
                font-weight: $font-weight-medium;
                color: $text-light-color;
            }
        }
    }
    & .app-version {
        margin: 15px;
        font-size: 0.9rem;
        color: $text-light-color;
    }
}

