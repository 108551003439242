@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.program_tab_content {
    & .program_tab_heading {
        font-size: $small-font;
        font-weight: $font-weight-semibold;
        color: $text-dark-color;
        padding: 0 0 20px 0;
    }
    & .program_data {
        @include flex(row,flex-start,flex-start);
        & .programName {
            width: 50%;
            border: 1px solid $border-color;
            border-radius: $button-borderradius;
            font-size: $small-font;
            font-weight: $font-weight-regular;
            color: $text-light-color;
            padding: 14px 16px;
            margin: 0 0 15px 0;
            cursor: pointer;

            &:hover {
                border-color: $primary-color;
            }
        }
    }
}