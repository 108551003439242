@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');

$primary-color: #0076BA;
$hover-color: #E6F1F8;
$disabled-color: #CECECE;
$text-dark-color: #1c1e1f;
$text-light-color: #5f6368;
$text-lighter-color: #a1a1a1;
$nav-background: #FAFBFC;
$border-color: #DADCE0;

$white-color:#ffffff;

$font-family: 'Inter', sans-serif;
$tiny-font:12.08px;
$small-font: 14px;
$medium-font: 16px;
$medium-font-2:18px;
$large-font:20px;
$larger-font:24px;

$font-weight-regular:400;
$font-weight-medium:500;
$font-weight-semibold:600;
$font-weight-bold:700;

$image-width: 24px;
$image-height: 24px;

$image-padding: 0 10px 0 0;

$button-margin: 10px 0;
$button-borderradius: 2px;
$statusBtn-borderradius: 50px;

$card-borderradius: 5px;

$button-border: none;

$button-danger-primary: #d34040;
$button-success-primary: #248024;
$button-warning-primary: #d1d150;

$button-danger-secondary: #dc6666;
$button-success-secondary: #509950;
$button-warning-secondary: #dada73;

$bg-danger: #FFCCCC;
$bg-success: #C5EBD4;
$bg-warning: #fffecc;

$text-danger: #740D0A;
$text-success: #184B21;
$text-warning: #72740a;

$border-radius-small:2px;

