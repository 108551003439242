@import "./../../styles/mixinflex";
@import "./../../styles/variables";

.addPatientContainer {
    @include formStyles;
    padding:0;
  & .addPatientheader {
    margin: 0;
    padding:10px 0 15px;
    @include flex(row, space-between, center);

    & .title {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  & .patientAddBtn {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width:480px){
    & .addPatientheader {
      padding:20px 0;
    }
}
}
.invalid {
  border: 2px solid #ff7a7a;
  // background-image: url(./../images/icon-error.svg);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.field-error {
  display: none;
  font-size: 0.85em;
  align-self: flex-start;
  position: relative;
  margin: 3px 0 0 0;
  color: #ff7a7a;
}
