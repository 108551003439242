@import './../../../styles/mixinflex'; 
@import './../../../styles/variables';

.clinic_page{
    max-width: 600px;
    margin: 20px auto 30px;
    @include flex(column,center,center);
    & .support-header{
        align-self:flex-start;
        margin:0 0 20px 0;
    }
    & .clinicPageDetails {
        width: 100%;
        height: fit-content;
        border: 1px solid $border-color;
        padding: 30px;

        & .clinic_page_details_container {
            width: 100%;
            @include flex(column,flex-start,flex-start);
            
            & .clinic_page_details_header {
                width: 100%;
                margin: 0 0 10px 0;
                @include flex(row,space-between,center);
    
                & .clinic_page_title {
                    margin: 0;
                    font-size: $large-font;
                    font-weight: $font-weight-semibold;
                    color: $text-dark-color;
                }
            }

            & .location {
                width:100%;
                @include flex(row,flex-start,center);
                & .address {
                    margin-right: 30px;
                }
            }
            
            & .clinic_name, & .address, & .city, & .phone_number, & .whatsapp_number, & .incentives {
                margin: 0 0 20px 0;
    
                span {
                    font-size: $tiny-font;
                    font-weight: $font-weight-regular;
                    color: $text-light-color;
                }
                h3 {
                    margin: 5px 0 0 0;
                    font-size: $medium-font;
                    font-weight: $font-weight-semibold;
                    color: $text-dark-color;
                }
            }
        }
    
        & .container {
            padding: 30px 0 !important;
            @include formStyles;
        }
    }
     
    & .clinic_page_tab_container {
        width: 100%;
        margin: 30px 0;
        & .tab_content{
            & .tab_content_header{
                @include flex(row,flex-end,center);
                margin:0 0 20px 0;
                & svg{
                    margin:0 8px 0 0;
                }
            }
        }
    }
}

.right_modal_width{
    width:350px;
    padding:0 !important;
   
}
 .modal_header{
    @include flex(row,space-between,center);
    margin:0 0 15px 0;
}