@import './../../../../../styles/mixinflex';
@import './../../../../../styles/variables';

.patientHomeTab {
    .patientTabRow {
        width: 95%;
        max-width: 800px;
        margin: 10px auto;
        cursor: pointer;
        & .patientRowDetails {
            border: 1px solid $border-color;
            border-radius:  $card-borderradius;
            padding: 10px 20px;
            margin: 0 0 5px 0;
            @include flex(column,flex-start,center);
            & .patientRowDetailsTop {
                width: 100%;
                @include flex(row,space-between,center);
                & .patientToken {
                    font-size: $small-font;
                    font-weight: $font-weight-medium;
                    color: $button-success-primary;
                    border-radius: $statusBtn-borderradius;
                    padding: 4px 0;
                    margin: 0;
                    font-style: italic;
                }
                & .date {
                    font-size: $small-font;
                    padding: 4px 0;
                    margin: 0;
                    font-style: italic;
                    color: $text-lighter-color;
                }
                & .editImage {
                    cursor: pointer;
                    height: $large-font;
                }
            }
            & .patientDetailsInfo {
                width: 100%;
                @include flex(row,flex-start,flex-start);
                flex-wrap: nowrap;
                padding: 15px 0 0 0;
                & .Detail {
                    @include flex(column,space-between,center);
                    width:100%;
                    margin:0 20px 0 0;
                    .title {
                        font-size: $small-font;
                        font-weight: $font-weight-regular;
                        color: $text-light-color;
                        margin: 0;
                        padding: 0 15px 0 0;
                    }
                    .value {
                        font-size: $medium-font;
                        font-weight: $font-weight-regular;
                        color: $text-dark-color;
                        margin: 0;
                    }
                }
                // & .doctorName {
                //     font-size: $small-font;
                //     font-weight: $font-weight-regular;
                //     color: $text-light-color;
                //     margin: 0;
                //     font-style: italic;
                // }
                // & .patientName{
                //     font-size: $medium-font;
                //     font-weight: $font-weight-semibold;
                //     color: $text-dark-color;
                //     margin: 0;
                // }
                // & .patientPhone {
                //     font-size: $small-font;
                //     font-weight: $font-weight-regular;
                //     color: $text-light-color;
                // }
            }
        }
    }
}
@media screen and (max-width:500px) {
    .patientHomeTab {
        & .patientTabRow {
            & .patientRowDetails{
                & .patientDetailsInfo{
                    @include flex(column,space-between,center);  
                }
                & .patientDetailsInfo {
                & .Detail{
                        @include flex(row,space-between,center);
                        margin:5px 0;
                        flex-wrap:nowrap;
                        & .title{
                            font-size:$small-font;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .paymentOptionContainer {
        & .paymentOptions {
            & .radioButtonGroup {
                width: 80%;
            }
        }
        & .form-element {
            & .input {
                width: 50%;
            }
        }
        & .submit_btn {
            margin:20px 0 0 0;
            @include flex(row,flex-end,center);
            & >*{
                width:100% ;
            }
        }
    }  
}

.paymentOptionContainer {
    margin: 15px;
    & .paymentOptions {
        & .paymentOptionTitle {
        font-size: $medium-font;
        font-weight: $font-weight-medium;
        margin: 0 0 15px;
        }
        & .radioButtonGroup {
            width:60%;
            margin: 0 0 10px 0;
            @include flex(row,flex-start,center);
            flex-wrap: nowrap;
            & label{
                width: 100%;
                border:1px solid $border-color;
                cursor:pointer;
                padding: 10px;
                margin: 0;
                & input[type='radio']{
                    cursor:pointer;
                    width:fit-content;
                    margin:0 10px 0 0;
                }
                &:hover{
                    background-color:$hover-color;
                }
            }
        
        }
    }
    & .form-element{
        @include flex(column,flex-start,space-between);
        margin:0 0 15px 0;
        & label {
            margin: 0 0 8px 0;
            font-size: $small-font;
            font-weight: $font-weight-regular;
            color: $text-dark-color;
        }
        & input {
            width:100% ;
            font-size:$medium-font;
            padding:10px 0 10px 8px;
            border: 1px solid $border-color;
            border-radius: $button-borderradius;
        }
    }    
}

.invalid {
    border: 2px solid #ff7a7a;
    // background-image: url(./../images/icon-error.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }
  .field-error {
    display: none;
    font-size: 0.85em;
    align-self: flex-start;
    position: relative;
    margin: 3px 0 0 0;
    color: #ff7a7a;
  }
  