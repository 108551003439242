@import './../../../../styles/mixinflex';
@import './../../../../styles/variables';

.AssistantCard {
    width: 90%;
    border: 1px solid $border-color;
    padding: 25px;
    flex-wrap: nowrap;
    margin: 0 0 20px 0;
    @include flex(column,flex-start,center);
    & .cardHeader {
        width: 100%;
        padding: 0 0 20px 0;
        @include flex(row,space-between,center);
        & .Title {
            font-size: $medium-font;
            font-weight: $font-weight-medium;
            color: $text-dark-color;
            margin: 0;
        }
 
        & .field-value {
            font-weight: $font-weight-regular;
            text-align: left;
        }

        & .revoked {
            @include revoked;
        }

        & .pending {
            @include pending;
        }

        & .approved {
            @include approved;
        }
        
    }
    & .AssistantDetails {
        @include flex(row,flex-start,center);
        margin: 0 0 20px 0;
        & .AssistantDetails_content_box {
            padding: 0 65px 0 0;
            margin:10px 0;
            @include flex(column,flex-start,flex-start);
            & .AssistantDetails_content_title {
                font-size: $small-font;
                font-weight: $font-weight-medium;
                color: $text-light-color;
                padding: 0 0 8px 0;
                margin: 0;
            }
            & .AssistantDetails_content_value {
                font-size: $small-font;
                font-weight: $font-weight-medium;
                color: $text-dark-color;
                margin: 0;
            }
        }
        & button {
            width: 100px;
            height: 30px;
            font-size: $small-font;
        }
        @media screen and (max-width:480px){
            @include flex(column,flex-start,center); 
            & .AssistantDetails_content_box {
                & .AssistantDetails_content_title {
                    padding: 0 0 5px 0;
                    margin: 0;
                }
            }
        }
    }
    & .btn-wrap {
        text-align: right;
        margin-top: 0px;

        button {
            padding: 5px 10px;
            font-size: $small-font;
            border-radius: $button-borderradius;
        }

        & .revoke-btn {
            color: $button-danger-primary;
            background-color: $white-color;
            border: 1px solid $button-danger-primary;
            margin: 0 10px 0 0;
            &:hover {
                background-color: $button-danger-primary;
                color: $white-color;
            }
        }

        & .approve-btn {
            color: $button-success-primary;
            background-color: $white-color;
            border: 1px solid $button-success-primary;
            &:hover {
                background-color: $button-success-primary;
                color: $white-color;
            }
        }
    }
    @media screen and (max-width:480px){
        padding:15px;
    }
}