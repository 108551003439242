@import './../../styles/mixinflex';
@import './../../styles/variables';

.notFoundComponent {
    max-width: 840px;
    width: 90%;
    height: 60vh;
    margin: 0 auto;
    @include flex(column,center,center);
    & .notFoundHeading {
        font-size: 3rem;
        font-weight: $font-weight-bold;
        color: $primary-color;
        text-align: center;
        margin: 0 0 10px 0;
    }
    & .notFoundDesc {
        font-size: $medium-font-2;
        font-weight: $font-weight-regular;
        color: $text-light-color;
        text-align: center;
        margin: 0 0 24px 0;
    }
    & a {
        text-decoration: none;
        color: $primary-color;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 480px) {
    
    .notFoundComponent {
        & .notFoundHeading {
            font-size: $larger-font;
        }
        & .notFoundDesc {
            font-size: $small-font;
        }
    }
}