@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.addAssistant {
    & .addAssistant-form {
        @media (max-width:480px) {
            & .bottomElement {
                position: fixed;
                @include flex(row, center, center);
                height: 60px;
                width: 100%;
                bottom: 0;
                left: 0;
                background-color: $nav-background;
                z-index: 1;
            }
        } 
    }
    & .registerForm {
        .clinicSearchGroup, .doctorSearchGroup {
            & .allDoctorCheckBox{
                user-select: none;
                width:100%;
                margin:10px 0 0 0;
                @include flex(row,flex-start,center);
                flex-wrap: nowrap;
                & #allDoctor{
                    width:fit-content;
                    margin:0 10px 0 0;
                }
                & label{
                    margin: 0 !important;
                }
            }
            & .SelectedDoctorContainer{
                background-color: $nav-background;
                width:100%;
                border-radius: $border-radius-small;
                height:auto;
                padding:5px;
                margin:0 0 10px 0;
                @include flex(row,flex-start,center);
                & .selectedDoctorItem{
                    @include flex(row,flex-start,center);
                    & span{
                        margin: 0 11px 0 0 ;
                        color:$primary-color;
                    }
                    & svg{
                        cursor: pointer;
                    }
                    padding:8px 5px 5px 5px;
                    margin:0 10px 10px 0;
                    background-color: $white-color;
                    border:1px solid $border-color;
                    border-radius: $border-radius-small;
                }

            }
            position: relative;
            & .suggestionBox {
                z-index: 10;
                margin: 5px 0 0 0;
                max-height: 142px;
                padding:10px 0;
                height: auto;
                width: 100%;
                position:absolute;
                overflow-y: auto;
                top:100%;
                background-color: white;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                @include flex(column,flex-start,center); 
                flex:1;
                flex-wrap: nowrap;
                // padding: 10px 0;
                & .searchSuggestion{
                    padding:6px 6px 6px 10px;
                    border: 1px solid $white-color;
                    @include flex(column,flex-start,center);
                    width:93%;
                    margin:0 auto;
                    & .searchValue{
                        font-size: $medium-font;
                    }
                    & .searchType{
                        font-size: $small-font;
                        color: $text-light-color ;
                        font-style: italic;
                    }
                    &:hover{
                        background-color:$nav-background;
                        border: 1px solid $border-color;
                        cursor:pointer;
                    }
                    
                }
                & .noDataFound{
                    padding:10px
                }
            }
        }
    }
}

