@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.container{
    max-width:830px;
    padding:0;
}

.my-wallet{
 margin:0 auto;
    & .topic{
        @include flex(row,flex-start,center);
        & .title{
            font-weight:$font-weight-semibold;
            margin:0 0 0 10px;
        }   
        margin:20px 0;
    }
    & .wallet-container{
        margin:0 auto;
        padding: 0 35px 35px;
        border:1px solid $border-color;
        max-width:615px;
        & .wallet-card-container{
            width:100%;
            @include flex(row,space-between,flex-start);
            & .wallet-card{
                max-width:250px;
                width:100%;
                border:1px solid $border-color;
                border-radius: 2px;
                padding:22px 0 22px 24px;
                margin: 0 0 30px 0 ; 
                & .wallet-card-heading{
                    font-size: $larger-font;
                    color:$text-dark-color;
                }
                & .wallet-card-caption{
                    margin:8px 0 0 0;
                    color:$text-light-color;    
                }
            }
        }   
        & .btn-holder{
            margin:0 auto;
            @include flex(row,flex-end,flex-start);
        }
        @media screen and (max-width:700px){
            max-width:100%;
            border:none;
        }
        @media screen and (max-width:622px){
            max-width:100%;
            border:none;
            & .wallet-card-container{
                @include flex(column,center,center);
                & .wallet-card{
                    & .wallet-card-heading{
                        font-size:$large-font;
                    }
                }
            }
            & .btn-holder{
                margin:0 auto;
                @include flex(row,center,center);
                width:100%;
                & button{
                    width:272px;  
                }
            }
        }
        @media screen and (max-width:350px){
            padding:0 16px 30px;
        }
    }
}

.withdrawModalContainer{
    width:350px;
    & .header{
        @include flex(row, space-between, center); 
        margin:0 0 10px 0;
        & .collect_payment_title{
            font-weight: $font-weight-medium;
        }
    }
    & .form-element{
        @include flex(column,flex-start,space-between);
        margin:0 0 15px 0;
        & label {
            margin: 0 0 8px 0;
            font-size: $small-font;
            font-weight: $font-weight-regular;
            color: $text-dark-color;
        }
        & input , & select{
            padding:10px 0 10px 8px;
            border: 1px solid $border-color;
            border-radius: $button-borderradius;
        }
        & input{
            width:100% ;
            font-size:$medium-font;
            &::placeholder{
                font-size: $small-font;
            }
            
        }
        & select{
            width:100%;
            color: $text-dark-color;
            background-color: $white-color;
            font-size: $medium-font;
        }
        & .invalid {
            border: 2px solid #ff7a7a;
            background-repeat: no-repeat;
            background-position: 95% 50%;
        }
        
        & .radioButtonGroup {
            width: 100%;
            margin: 0 0 10px 0;
            @include flex(row, flex-start, center);
            flex-wrap: nowrap;
            & label {
            width: 100%;
            border: 1px solid $border-color;
            cursor: pointer;
            padding: 10px;
            margin: 0;
            & input[type="radio"] {
                cursor: pointer;
                width: fit-content;
                margin: 0 10px 0 0;
            }
            &:hover {
                background-color: $hover-color;
            }
            }
        }
    }  
    & .form-element {
        & h3 {
          font-size: $small-font;
          font-weight: $font-weight-regular;
          margin: 10px 0;
        }
        
    }
    & .continue_btn{
        @include flex(row, flex-end, center); 
    }
}

@media (max-width:480px){
    .withdrawModalContainer{
        width:100%;
        & .continue_btn{
            @include flex(row,flex-end,center);
            & >*{
                width:100%;
            }
        }
    }

    .wallet-container {
        .bottomElement {
            left: 0;
        }
    }
}