@import './../../styles/mixinflex';
@import './../../styles/variables';

.addProgramHeader {
    max-width: 840px;
    width: 100%;
    margin: 20px auto;
    padding:0 16px ;
    & .addprogram_header_content{
        max-width: 830px;
        width: 100%;
        margin: 0;
        // padding: 10px 16px;
        @include flex(row,flex-start,center);
    }
    
    & a {
        & .back-image {
            padding: 5px 30px 0 0;
        }
    }
    & .addProgramTitle {
        font-size: $medium-font;
        font-weight: 600;
        color: $text-light-color;
        margin: 0;
    }
    & .headerBtn {
        border: $button-border;
        border-radius: $button-borderradius;
        font-size: $small-font;
        text-decoration: none;
        padding: 6px 12px;
        margin-left: auto;
    }
    .primaryBtn {
        background-color: $primary-color;
        color: $white-color;
    }
    .secondaryBtn {
        background-color: $hover-color;
        color: $primary-color;
    }
    .headerBackground {
        background-color: $nav-background;
    }
    .fluid{
        width:100%;
    }
}