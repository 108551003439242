@import './../../styles/mixinflex';
@import './../../styles/variables';

.select_role_container {
    max-width: 400px;
    padding:20px 40px;
    height: auto;
    border: 1px solid $border-color;
    @include flex(column,center,flex-start);
    margin: 120px auto 0;
    & .logo {
        width: 70px;
        padding: 80px 0 20px;
        & .logo_image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .form-element {
        width:100%;
        @include flex(column,flex-start,flex-start);
        & label {
            font-size: $small-font;
            font-weight: $font-weight-regular;
            color: $text-dark-color;
            padding: 0 0 8px 0;
        }
        & select{
            width:inherit;
            margin:0 0 15px 0;
        }
        & #select-role{
            height: 40px;
            padding: 0 0 0 15px;
            border: 1px solid $border-color;
            background-color: $white-color;
        }
    }
}
@media (max-width: 480px) {
    .select_role_container {
        width: 100%;
        border: none;

        & .form-element {
            & input {
                width: 320px;
            }
        }
        & .getOtp-btn {
            width: 100%;
        }
    }
}

@media (max-width: 390px) {
    .select_role_container {
        & .form-element {
            & input {
                width: 270px;
            }
        }
        & .getOtp-btn {
            width: 100%;
        }
    }
}
