@import "./../../styles/mixinflex";
@import "./../../styles/variables";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(133, 133, 133, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.modal-content {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
  position: absolute;
  overflow-y: auto;
  padding: 20px 24px;
  border-radius: 5px;
  z-index: 5;
}

.mobile-modalContent {
  width: 100%;
  height: 100%;
  padding:0;
}

.close-btn {
  cursor: pointer;
  width: fit-content;
}

.appear-right {
  right: 0;
  height: 100%;
}

.appear-center {
  width:fit-content;
}

.showHigher {
  z-index: 4;

}

.showLower {
  z-index: 3;
}

.bottom{
  max-width:100%! important;
  width:100%;
  position:fixed;
  bottom:0;
  padding:16px !important;
}

.adduserForm {
  width:350px;
  // width:100%;
  margin: 0 auto;
  & .profileHeader {
    margin: 20px 0;
    @include flex(row, space-between, center);
    & .edit-btn {
      border: $button-border;
      border-radius: $button-borderradius;
    }
  }
  & .profileItems {
    padding: 50px 0 0 55px;
    &:last-child {
      padding-bottom: 50px;
    }
    & .itemLabel {
      font-size: $small-font;
      padding: 0 0 10px 0;
      text-transform: capitalize;
    }
    & .itemValue {
      font-size: $medium-font;
      padding: 0;
      margin: 0;
    }
  }
}
