@mixin flex($direction,$main-axis,$cross-axis) {
    display: flex;
    flex-flow: $direction wrap;
    @if $direction == row {
        justify-content: $main-axis;
        align-items: $cross-axis;
    }
    @else
    {
        justify-content: $cross-axis;
        align-items:  $main-axis;
    }
}

@mixin dropdown {
    position: absolute;
    width: 140px;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    
    & .list-items, .user-list, .options-list {
        list-style-type: none;
        padding: 6px;
        margin: 0;
        & li, & .listItem1, & .listItem2, & a {
            font-size: $small-font;
            position: relative;
            padding: 6px;
            cursor: pointer;
            color: $text-light-color;
            &:hover {
                background-color: $hover-color;
                color: $primary-color;
            }
        }
        & .sub-select, & .search-select {
            position: absolute;
            left: 145px;
            top: 5px;
            width: 150px;
            background-color: white;
            font-size: $small-font;

            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
            & .sub-list, & .search-list {
                padding: 6px;
                margin: 0;
                list-style-type: none;
                & li {
                    cursor: pointer;
                    padding: 6px;
                    font-size: $small-font;
                    color: $text-light-color;
                    &:hover {
                        background-color: $hover-color;
                        color: $primary-color;
                    }
                }
            }
        }
        & .item2-subselect {
            top: 32px;
        }
    }
}

@mixin formStyles {
    padding: 30px 30px 10px;
    & .title {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 30px 0;
        padding: 0;
        text-align: center;
    }
    .registerForm {
        
        & .form-element{
            @include flex(column,flex-start,space-between);
            margin:0 0 15px 0;
            & label {
                margin: 0 0 8px 0;
                font-size: $small-font;
                font-weight: $font-weight-regular;
                color: $text-dark-color;
            }
            & input , & select{
                padding:10px 0 10px 8px;
                border: 1px solid $border-color;
                border-radius: $button-borderradius;
            }
            & input {
                width:100% ;
                font-size:$medium-font;
            }
            & select{
                width:100%;
                color: $text-dark-color;
                background-color: $white-color;
                font-size: $medium-font;
            }
            & .invalid {
                border: 2px solid #ff7a7a;
                background-repeat: no-repeat;
                background-position: 95% 50%;
            }
            
            & .radioButtonGroup{
                width:50%;
                margin:0 0 10px 0;
                & input[type='radio']{
                    cursor:inherit;
                    width:fit-content;
                    margin:0 10px 0 0;
                }
                @include flex(row,flex-start,center);
                flex-wrap: nowrap;
                & label{
                    cursor:pointer;
                    margin:0;
                }
            }
            & .radioButtonGroup{
                width:60%;
                @include flex(row,flex-start,center);
                flex-wrap: nowrap;
                & label{
                    width:100%;
                    border:1px solid $border-color;
                    cursor:pointer;
                    padding:10px;
                    margin:0;
                    & input[type='radio']{
                        cursor:pointer;
                        width:fit-content;
                        margin:0 10px 0 0;
                    }
                    &:hover{
                        background-color:$hover-color;
                    }
                }
               
            }
        }
        & button:last-child{
            margin: 10px 0 0 0;
            padding:6px 12px;
        }
    }
}

@mixin revoked {
    color: $button-danger-primary;
    font-size: $small-font; 
}

@mixin pending {
    color: $button-warning-primary;
    font-size: $small-font; 
}

@mixin approved {
    color: $button-success-primary;
    font-size: $small-font; 
}

@mixin noData {
    font-size: $large-font;
    font-weight: $font-weight-semibold;
    margin: 50px auto;
    color: $text-dark-color;
}

@mixin fixedBottomStyles {
    position:fixed;
    height:50px;
    width: 100%;
    bottom:0;
    //left: 0;
    margin: 0 auto;
    background-color: $nav-background;
    z-index:1;
}