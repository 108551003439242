@import './../../styles/mixinflex';
@import './../../styles/variables';

.myAccount_Container {
    max-width: 500px;
    width:auto;
    height: fit-content;
    border: 1px solid $border-color;
    padding: 30px;
    margin: 50px auto 30px;
    span {
        font-size: $tiny-font;
        font-weight: $font-weight-regular;
        color: $text-light-color;
        margin: 0 0 5px 0;
    }
    h3 {
        font-size: $medium-font;
        font-weight: $font-weight-semibold;
        color: $text-dark-color;
    }
    & .myAccount_Header {
        padding: 0 0 25px 0;
        @include flex(row,space-between,center);
        & .headerGroup{
            @include flex(row,flex-start,center);
            & .myAccount_Title {
                margin:0 0 0 10px;
                font-size: $large-font;
                font-weight: $font-weight-semibold;
                color: $text-dark-color;
            }
        }
    }
    & .myAccount_name {
        @include flex(column,flex-start,center);
        & .first_name {
           margin : 0 0 20px 0;
        }
    }
    & .myAccount_name, & .clinic_name, & .practicing_as, & .medical_council, & .email, & .phone_number, & .ayush_registered {
        margin: 0 0 20px 0;
    }
    & .container {
        @include formStyles;
        padding: 0 0 0 15px !important;
        & .ayush_registered{
            @include flex(row,flex-start,center);
            & span:last-child{
                margin:0 0 0 10px;
            }
        }
    } 
    & .EditmyAccount_form {
        & .form-element {
            & .clinic_component {
                width: 90%;
                max-height: 200px;
                overflow-y: auto;
                border: 1px solid $border-color;
                padding: 15px;
                & .clinic_component_header {
                    @include flex(row,space-between,center);
                    margin: 0 0 15px 0;
                    & label {
                        margin: 0;
                    }
                    & svg {
                        border: 1px solid $white-color;
                        &:hover{
                            cursor: pointer;
                            border: 1px solid $primary-color;
                        }
                    }
                }
                & .input_container {
                    @include flex(row,center,center);
                    flex-wrap: nowrap;
                    margin: 0 0 10px 0;
                    & input {
                        width: 90%;
                        margin: 0 15px 0 0;
                    }
                    & svg {
                        border: 1px solid $white-color;
                        &:hover{
                            cursor: pointer;
                            border: 1px solid $primary-color;
                            & path{
                                stroke:$primary-color;
                            } 
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:570px){
        border:none;
        padding:30px 16px;
        & .myAccount_Header {
            & .headerGroup{
                & .myAccount_Title {
                    margin:0 0 0 10px;
                    font-size: $medium-font-2;
                }
            }
        }
        margin:0;
    }

}
.myAccountBottomElement{
    position:fixed;
    @include flex(row,center,center);
    height:50px;
    width:100%;
    bottom:0;
    background-color: $nav-background;
    & button{
        width:96%;
    }
}