@import './../../styles/mixinflex';
@import './../../styles/variables';

.logout_container{
    padding:0 20px 30px 20px;
    width:250px;
    & .logo{
        width:50px;
        height:45px;
        margin: 20px auto;
    }
    & .main_content{
        font-size: $medium-font;
        font-weight: $font-weight-medium;
        margin:0 auto 20px;
        text-align: center;
    }
    & .button_section{
        @include flex(column,center,center);

        & button:first-child{
            margin:0 0 10px 0;
        }
        & button{
            width:100%;
        }
    }
    @media screen and (max-width:480px){
        width:100%;
    }
}
