@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.doctor-register {
    .container {
        @include formStyles;
        & .registerDoctor-form{
            @media (max-width:480px){
            & .bottomElement {
                position: fixed;
                @include flex(row, center, center);
                height: 60px;
                width: 100%;
                bottom: 0;
                left: 0;
                background-color: $nav-background;
                z-index: 1;
                }
            }
        }
    }
    .invalid {
        border: 2px solid #ff7a7a;
        background-repeat: no-repeat;
        background-position: 95% 50%;
    }    
    .field-error {
        display: none;
        font-size: 0.85em;
        align-self: flex-start;
        position: relative;
        color: #ff7a7a;
        padding: 8px 0 0 0;
    }

    .clinicSearchGroup {
        position: relative;
        & .suggestionBox {
            z-index: 10;
            margin: 5px 0 0 0;
            max-height: 142px;
            padding:10px 0;
            height: auto;
            width: 100%;
            position:absolute;
            overflow-y: auto;
            top:100%;
            background-color: white;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
            @include flex(column,flex-start,center); 
            flex:1;
            flex-wrap: nowrap;
            
            & .searchSuggestion{
                padding:6px 6px 6px 10px;
                border: 1px solid $white-color;
                @include flex(column,flex-start,center);
                width:93%;
                margin:0 auto;
                & .searchValue{
                    font-size: $medium-font;
                }
                & .searchType{
                    font-size: $small-font;
                    color: $text-light-color ;
                    font-style: italic;
                }
                &:hover{
                    background-color:$nav-background;
                    border: 1px solid $border-color;
                    cursor:pointer;
                }
                
            }
            & .noDataFound{
                padding:10px
            }
        }
    }
}