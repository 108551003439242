@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.patient_details_component {
    & .patient_details_container {
        max-width: 840px;
        width:100%;
        margin: 10px auto 0;
        & .patient_details {
            padding: 20px 0 0 0;
            // @include flex(column,flex-start,flex-start);
            flex-wrap: nowrap;
            & .assistant_patient_profile {
                width: 100%;
                padding: 0;
                margin: 0 auto 35px;
            }
        }
        & .assistant_patient_appointments { 
            display: none;
            width: 100%;
            padding: 50px 0;
        }
        @media screen and (max-width:768px){
            & .assistant_patient_appointments { 
                padding: 20px 0;
            } 
        }
    }
}