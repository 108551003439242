@import './../../styles/mixinflex';
@import './../../styles/variables';

.htmlContent.v1 {
    display: none;
    position: fixed;
    top: 0;
    left: 0;

    ol,
    table td,
    table th {
        padding: 0
    }

    .c15,
    .c47 {
        border-right: 0 dotted #b7b7b7
    }

    .c2,
    .c28 {
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c2,
    .c25,
    .c28,
    .c3 {
        text-align: left
    }

    h1,
    h2 {
        padding-bottom: 6pt
    }

    ul.lst-kix_10vycow0f4vl-0,
    ul.lst-kix_10vycow0f4vl-1,
    ul.lst-kix_10vycow0f4vl-2,
    ul.lst-kix_10vycow0f4vl-3,
    ul.lst-kix_10vycow0f4vl-4,
    ul.lst-kix_10vycow0f4vl-5,
    ul.lst-kix_10vycow0f4vl-6,
    ul.lst-kix_10vycow0f4vl-7,
    ul.lst-kix_10vycow0f4vl-8,
    ul.lst-kix_415ell3apmur-0,
    ul.lst-kix_415ell3apmur-1,
    ul.lst-kix_415ell3apmur-2,
    ul.lst-kix_415ell3apmur-3,
    ul.lst-kix_415ell3apmur-4,
    ul.lst-kix_415ell3apmur-5,
    ul.lst-kix_415ell3apmur-6,
    ul.lst-kix_415ell3apmur-7,
    ul.lst-kix_415ell3apmur-8,
    ul.lst-kix_aa7b9fuap2e6-0,
    ul.lst-kix_aa7b9fuap2e6-1,
    ul.lst-kix_aa7b9fuap2e6-2,
    ul.lst-kix_aa7b9fuap2e6-3,
    ul.lst-kix_aa7b9fuap2e6-4,
    ul.lst-kix_aa7b9fuap2e6-5,
    ul.lst-kix_aa7b9fuap2e6-6,
    ul.lst-kix_aa7b9fuap2e6-7,
    ul.lst-kix_aa7b9fuap2e6-8,
    ul.lst-kix_e2hch7a8aobt-0,
    ul.lst-kix_e2hch7a8aobt-1,
    ul.lst-kix_e2hch7a8aobt-2,
    ul.lst-kix_e2hch7a8aobt-3,
    ul.lst-kix_e2hch7a8aobt-4,
    ul.lst-kix_e2hch7a8aobt-5,
    ul.lst-kix_e2hch7a8aobt-6,
    ul.lst-kix_e2hch7a8aobt-7,
    ul.lst-kix_e2hch7a8aobt-8,
    ul.lst-kix_e3miac3i3ifl-0,
    ul.lst-kix_e3miac3i3ifl-1,
    ul.lst-kix_e3miac3i3ifl-2,
    ul.lst-kix_e3miac3i3ifl-3,
    ul.lst-kix_e3miac3i3ifl-4,
    ul.lst-kix_e3miac3i3ifl-5,
    ul.lst-kix_e3miac3i3ifl-6,
    ul.lst-kix_e3miac3i3ifl-7,
    ul.lst-kix_e3miac3i3ifl-8,
    ul.lst-kix_gyjbison90k0-0,
    ul.lst-kix_gyjbison90k0-1,
    ul.lst-kix_gyjbison90k0-2,
    ul.lst-kix_gyjbison90k0-3,
    ul.lst-kix_gyjbison90k0-4,
    ul.lst-kix_gyjbison90k0-5,
    ul.lst-kix_gyjbison90k0-6,
    ul.lst-kix_gyjbison90k0-7,
    ul.lst-kix_gyjbison90k0-8,
    ul.lst-kix_ier6pdmszp1b-0,
    ul.lst-kix_ier6pdmszp1b-1,
    ul.lst-kix_ier6pdmszp1b-2,
    ul.lst-kix_ier6pdmszp1b-3,
    ul.lst-kix_ier6pdmszp1b-4,
    ul.lst-kix_ier6pdmszp1b-5,
    ul.lst-kix_ier6pdmszp1b-6,
    ul.lst-kix_ier6pdmszp1b-7,
    ul.lst-kix_ier6pdmszp1b-8,
    ul.lst-kix_np11wu9tnt5-0,
    ul.lst-kix_np11wu9tnt5-1,
    ul.lst-kix_np11wu9tnt5-2,
    ul.lst-kix_np11wu9tnt5-3,
    ul.lst-kix_np11wu9tnt5-4,
    ul.lst-kix_np11wu9tnt5-5,
    ul.lst-kix_np11wu9tnt5-6,
    ul.lst-kix_np11wu9tnt5-7,
    ul.lst-kix_np11wu9tnt5-8,
    ul.lst-kix_x4psp5homvbb-0,
    ul.lst-kix_x4psp5homvbb-1,
    ul.lst-kix_x4psp5homvbb-2,
    ul.lst-kix_x4psp5homvbb-3,
    ul.lst-kix_x4psp5homvbb-4,
    ul.lst-kix_x4psp5homvbb-5,
    ul.lst-kix_x4psp5homvbb-6,
    ul.lst-kix_x4psp5homvbb-7,
    ul.lst-kix_x4psp5homvbb-8 {
        list-style-type: none
    }

    .lst-kix_10vycow0f4vl-2>li:before,
    .lst-kix_10vycow0f4vl-5>li:before,
    .lst-kix_10vycow0f4vl-8>li:before,
    .lst-kix_415ell3apmur-2>li:before,
    .lst-kix_415ell3apmur-5>li:before,
    .lst-kix_415ell3apmur-8>li:before,
    .lst-kix_aa7b9fuap2e6-2>li:before,
    .lst-kix_aa7b9fuap2e6-5>li:before,
    .lst-kix_aa7b9fuap2e6-8>li:before,
    .lst-kix_e2hch7a8aobt-2>li:before,
    .lst-kix_e2hch7a8aobt-5>li:before,
    .lst-kix_e2hch7a8aobt-8>li:before,
    .lst-kix_e3miac3i3ifl-2>li:before,
    .lst-kix_e3miac3i3ifl-5>li:before,
    .lst-kix_e3miac3i3ifl-8>li:before,
    .lst-kix_gyjbison90k0-2>li:before,
    .lst-kix_gyjbison90k0-5>li:before,
    .lst-kix_gyjbison90k0-8>li:before,
    .lst-kix_ier6pdmszp1b-2>li:before,
    .lst-kix_ier6pdmszp1b-5>li:before,
    .lst-kix_ier6pdmszp1b-8>li:before,
    .lst-kix_np11wu9tnt5-2>li:before,
    .lst-kix_np11wu9tnt5-5>li:before,
    .lst-kix_np11wu9tnt5-8>li:before,
    .lst-kix_x4psp5homvbb-2>li:before,
    .lst-kix_x4psp5homvbb-5>li:before,
    .lst-kix_x4psp5homvbb-8>li:before {
        content: "\0025a0   "
    }

    .lst-kix_10vycow0f4vl-0>li:before,
    .lst-kix_10vycow0f4vl-3>li:before,
    .lst-kix_10vycow0f4vl-6>li:before,
    .lst-kix_415ell3apmur-3>li:before,
    .lst-kix_415ell3apmur-6>li:before,
    .lst-kix_aa7b9fuap2e6-0>li:before,
    .lst-kix_aa7b9fuap2e6-3>li:before,
    .lst-kix_aa7b9fuap2e6-6>li:before,
    .lst-kix_e2hch7a8aobt-0>li:before,
    .lst-kix_e2hch7a8aobt-3>li:before,
    .lst-kix_e2hch7a8aobt-6>li:before,
    .lst-kix_e3miac3i3ifl-3>li:before,
    .lst-kix_e3miac3i3ifl-6>li:before,
    .lst-kix_gyjbison90k0-3>li:before,
    .lst-kix_gyjbison90k0-6>li:before,
    .lst-kix_ier6pdmszp1b-0>li:before,
    .lst-kix_ier6pdmszp1b-3>li:before,
    .lst-kix_ier6pdmszp1b-6>li:before,
    .lst-kix_np11wu9tnt5-0>li:before,
    .lst-kix_np11wu9tnt5-3>li:before,
    .lst-kix_np11wu9tnt5-6>li:before,
    .lst-kix_x4psp5homvbb-0>li:before,
    .lst-kix_x4psp5homvbb-3>li:before,
    .lst-kix_x4psp5homvbb-6>li:before {
        content: "\0025cf   "
    }

    .lst-kix_10vycow0f4vl-1>li:before,
    .lst-kix_10vycow0f4vl-4>li:before,
    .lst-kix_10vycow0f4vl-7>li:before,
    .lst-kix_415ell3apmur-1>li:before,
    .lst-kix_415ell3apmur-4>li:before,
    .lst-kix_415ell3apmur-7>li:before,
    .lst-kix_aa7b9fuap2e6-1>li:before,
    .lst-kix_aa7b9fuap2e6-4>li:before,
    .lst-kix_aa7b9fuap2e6-7>li:before,
    .lst-kix_e2hch7a8aobt-1>li:before,
    .lst-kix_e2hch7a8aobt-4>li:before,
    .lst-kix_e2hch7a8aobt-7>li:before,
    .lst-kix_e3miac3i3ifl-1>li:before,
    .lst-kix_e3miac3i3ifl-4>li:before,
    .lst-kix_e3miac3i3ifl-7>li:before,
    .lst-kix_gyjbison90k0-0>li:before,
    .lst-kix_gyjbison90k0-1>li:before,
    .lst-kix_gyjbison90k0-4>li:before,
    .lst-kix_gyjbison90k0-7>li:before,
    .lst-kix_ier6pdmszp1b-1>li:before,
    .lst-kix_ier6pdmszp1b-4>li:before,
    .lst-kix_ier6pdmszp1b-7>li:before,
    .lst-kix_np11wu9tnt5-1>li:before,
    .lst-kix_np11wu9tnt5-4>li:before,
    .lst-kix_np11wu9tnt5-7>li:before,
    .lst-kix_x4psp5homvbb-1>li:before,
    .lst-kix_x4psp5homvbb-4>li:before,
    .lst-kix_x4psp5homvbb-7>li:before {
        content: "\0025cb   "
    }

    .lst-kix_415ell3apmur-0>li:before,
    .lst-kix_e3miac3i3ifl-0>li:before {
        content: "\002b29   "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol {
        margin: 0
    }

    .c47 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #000;
        border-width: 0;
        width: 162pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c15,
    .c21 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c15 {
        border-width: 0;
        width: 144pt;
        border-top: 0 dotted #000;
        border-bottom: 0 dotted #000
    }

    .c21,
    .c30,
    .c40 {
        border-right: 0 dotted #b7b7b7
    }

    .c21 {
        border-width: 0;
        width: 87.8pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c30,
    .c38,
    .c40 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c30,
    .c40 {
        border-width: 0;
        width: 113.2pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c33,
    .c38 {
        border-right: 0 dotted #b7b7b7
    }

    .c38 {
        border-width: 0;
        width: 144pt;
        border-top: 0 dotted #000;
        border-bottom: 0 dotted #b7b7b7
    }

    .c33,
    .c8 {
        border-left: 0 dotted #b7b7b7;
        width: 155.2pt;
        padding: 5pt;
        vertical-align: top
    }

    .c33 {
        border-width: 0;
        border-top: 0 dotted #000;
        border-bottom: 0 dotted #000
    }

    .c8 {
        border-right: 0 dotted #b7b7b7;
        border-width: 1pt 0;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 1pt dotted #b7b7b7
    }

    .c41 {
        padding: 5pt;
        vertical-align: top;
        border-right: 0 dotted #000;
        border-left: 0 dotted #b7b7b7;
        border-width: 1pt 0;
        width: 229.5pt;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 1pt dotted #b7b7b7
    }

    .c29,
    .c50 {
        border-right: 0 dotted #b7b7b7
    }

    .c50 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #000;
        border-width: 0;
        width: 141.8pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c12,
    .c29 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c29 {
        border-width: 0;
        width: 85.5pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c12,
    .c23 {
        border-right: 0 dotted #b7b7b7
    }

    .c12 {
        border-width: 0;
        width: 141.8pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c23,
    .c37 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c23 {
        border-width: 0;
        width: 87pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c37,
    .c44 {
        border-right: 0 dotted #b7b7b7
    }

    .c37 {
        border-width: 0;
        width: 144pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c10,
    .c44 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c44 {
        border-width: 1pt 0;
        width: 198.8pt;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 1pt dotted #b7b7b7
    }

    .c10,
    .c7 {
        border-right: 0 dotted #b7b7b7
    }

    .c10 {
        border-width: 0;
        width: 155.2pt;
        border-top: 0 dotted #000;
        border-bottom: 0 dotted #b7b7b7
    }

    .c45,
    .c7 {
        padding: 5pt;
        vertical-align: top;
        border-left: 0 dotted #b7b7b7
    }

    .c7 {
        border-width: 1pt 0 0;
        width: 12.8pt;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c18,
    .c45 {
        border-right: 0 dotted #b7b7b7
    }

    .c45 {
        border-width: 1pt 0;
        width: 144pt;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 1pt dotted #b7b7b7
    }

    .c16,
    .c18 {
        border-left: 0 dotted #b7b7b7;
        padding: 5pt;
        vertical-align: top
    }

    .c16 {
        border-right: 0 dotted #000;
        border-width: 1pt 0;
        width: 249pt;
        border-top: 1pt dotted #b7b7b7;
        border-bottom: 1pt dotted #b7b7b7
    }

    .c18 {
        border-width: 0;
        width: 155.2pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c1 {
        padding: 5pt;
        vertical-align: top;
        border-right: 0 dotted #b7b7b7;
        border-left: 0 dotted #b7b7b7;
        border-width: 0;
        width: 162pt;
        border-top: 0 dotted #b7b7b7;
        border-bottom: 0 dotted #b7b7b7
    }

    .c22,
    .c5 {
        text-decoration: none;
        vertical-align: baseline;
        font-family: Arial;
        font-style: normal
    }

    .c5 {
        color: #434343;
        font-weight: 700;
        font-size: 9pt
    }

    .c0,
    .c11,
    .c22 {
        font-weight: 400
    }

    .c22 {
        color: #666;
        font-size: 11pt
    }

    .c11 {
        color: #434343;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: Arial;
        font-style: normal
    }

    .c0,
    .c19 {
        color: #000;
        text-decoration: none;
        vertical-align: baseline;
        font-family: Arial;
        font-style: normal
    }

    .c0,
    .c19,
    .c34,
    li,
    p {
        font-size: 11pt
    }

    .c19,
    .c20 {
        font-weight: 700
    }

    .c2 {
        padding-top: 0;
        padding-bottom: 0
    }

    /*.c28 {
        padding-top: 12pt;
        padding-bottom: 12pt
    }*/

    .c25,
    .c3,
    .c48 {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1
    }

    .c3 {
        height: 11pt
    }

    .c34 {
        text-decoration: none;
        vertical-align: baseline;
        font-family: Arial;
        font-style: normal
    }

    .c32,
    h6 {
        font-style: italic
    }

    .c48 {
        text-align: right
    }

    .c49 {
        padding-top: 20pt;
        line-height: 1.15;
        text-align: center
    }

    .c26 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c4 {
        background-color: #fff;
        max-width: 530pt;
        padding: 0 30pt;
    }

    .subtitle,
    .title {
        padding-top: 0;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c32 {
        font-size: 9pt;
        color: #222
    }

    .c20 {
        font-size: 13pt;
        color: #666
    }

    .c27,
    .c36,
    h3 {
        color: #434343
    }

    .c36 {
        font-weight: 400
    }

    .c13 {
        margin-left: 36pt;
        padding-left: 0
    }

    .c39,
    p {
        margin: 0
    }

    .c42 {
        orphans: 2;
        widows: 2
    }

    .c27 {
        font-weight: 700
    }

    .title,
    h1,
    h2,
    li,
    p {
        color: #000;
        font-family: Arial
    }

    .c39 {
        padding: 0
    }

    .c24 {
        height: 42pt
    }

    .c46 {
        height: 22pt
    }

    .c35 {
        height: 38.5pt
    }

    .c31 {
        height: 23.1pt
    }

    .c6 {
        height: 39pt
    }

    .c17 {
        height: 21pt
    }

    .c9 {
        height: 11pt
    }

    .d0 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 25pt;
        font-style: italic;
        padding: 0 10pt;
    }

    .c14 {
        background-color: #fefefe
    }

    .c43 {
        height: 20.5pt
    }

    .title {
        font-size: 26pt;
        padding-bottom: 3pt
    }

    .subtitle {
        color: #666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: Arial
    }

    h1 {
        padding-top: 20pt;
        font-size: 20pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        font-size: 16pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: Arial;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: Arial;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5,
    h6 {
        padding-top: 12pt;
        color: #666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: Arial;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .custom {
        bottom: 3mm;
        position: fixed;
    }

    html, body {
        width:  595pt;
        height: 842pt;
    }
}

.v1.viewable {
    /*position: absolute;*/
    display: flex !important;
    width: 100%;  
    justify-content: center;
    /*top: 60px;*/
    background-color: #0000007a;
    height: 100%;
    overflow: auto;
    body.c4 {
        width: 100%;
        padding: 0 10px;
        border: 1px solid #dddddd;
        box-shadow: 2px 4px 11px 2px #434343;
    }

    .custom {
        visibility: hidden;
    }
}

.printReportButtonWrap {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    margin-left: -100px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-bottom: 10px;

    & .printReportButton {
        margin-right: 50px;
        background-color: $primary-color;
        color: white;
    }

    & .cancelReportButton {
        margin-left: 50px;
    }
}


.htmlContent.v2 {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    
    .custom {
        bottom: 3mm;
        position: fixed;
    }

    html, body {
        width:  595pt;
    }

    body {
        background-color: #fff;
        max-width: 530pt;
        padding: 0 30pt;
    }
    
    .tok {
        float: left
    }

    .dt {
        float: right
    }

    .mainContainer {
        margin: auto;
        padding: 20px;
    }

    .k10_distress {
        margin: auto;
        padding: 20px;

        .c11 {
            border-right-style: solid;
            padding: 2pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 400pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c27 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c10 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 159.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c25 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 48.1pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c30 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 36.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c4 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 46.8pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c12 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 69.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c6 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 66pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c16 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 63pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c42 {
            -webkit-text-decoration-skip: none;
            color: #000;
            font-weight: 700;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c13 {
            margin-left: -22.5pt;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }
    
        .c2 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c0 {
            color: #000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c26 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c9 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c31 {
            color: #666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c28 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c24 {
            color: #000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c35 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            margin-right: -45pt
        }
    
        .c3 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c5 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: right
        }
    
        .c32 {
            /*padding-top: 12pt;*/
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c34 {
            /*padding-top: 12pt;*/
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }
    
        .c18 {
            text-decoration-skip-ink: none;
            font-size: 10pt;
            -webkit-text-decoration-skip: none;
            font-weight: 700;
            text-decoration: underline
        }
    
        .c1 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left;
            height: 11pt
        }
    
        .c14 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: center
        }
    
        .c41 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c7 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left
        }
    
        .c19 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c33 {
            background-color: #fefefe;
            font-size: 9pt;
            font-style: italic;
            color: #222
        }
    
        .c17 {
            font-size: 13pt;
            color: #666;
            font-weight: 700
        }
    
        .c21 {
            background-color: #fff;
            max-width: 468pt;
            padding: 0 72pt 0 72pt
        }
    
        .c15 {
            font-size: 12pt;
            font-weight: 700
        }
    
        .c36 {
            height: 17.9pt
        }
    
        .c38 {
            height: 17.1pt
        }
    
        .c40 {
            margin-right: -45pt
        }
    
        .c43 {
            height: 51.6pt
        }
    
        .c20 {
            height: 11pt
        }
    
        .c8 {
            height: 0
        }
    
        .c37 {
            height: 25.2pt
        }
    
        .c22 {
            height: 20.9pt
        }
    
        .c23 {
            height: 23.7pt
        }
    
        .c29 {
            height: 15.6pt
        }
    
        li {
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    
        p {
            margin: 0;
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    }
    
    .phq9_gad7 {

        margin: auto;
        padding: 20px;

        .c27 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 36.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c33 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 63pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c8 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 53.2pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c14 {
            border-right-style: solid;
            padding: 2pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 288.8pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c40 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 52.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c3 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 75pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c15 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c5 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #000;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 1pt;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 54.8pt;
            border-top-color: #000;
            border-bottom-style: solid
        }
    
        .c42 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 177pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c30 {
            -webkit-text-decoration-skip: none;
            color: #000;
            font-weight: 700;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c12 {
            color: #000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c19 {
            color: #666;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c1 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c13 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c20 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c21 {
            color: #000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c16 {
            margin-left: -22.5pt;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            text-align: left;
            /*height: 11pt*/
        }
    
        .c29 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            text-align: left;
            /*margin-right: -45pt*/
        }
    
        .c0 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left;
            height: 11pt
        }
    
        .c28 {
            /*margin-left: -34.5pt;*/
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c2 {
            background-color: #fefefe;
            font-size: 9pt;
            font-style: italic;
            color: #222
        }
    
        .c11 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left
        }
    
        .c37 {
            padding-top: 5pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            text-align: left
        }
    
        .c26 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            text-align: right
        }
    
        .c18 {
            padding-top: 8pt;
            padding-bottom: 8pt;
            line-height: 1.15;
            text-align: center
        }
    
        .c43 {
            text-decoration-skip-ink: none;
            font-size: 12pt;
            -webkit-text-decoration-skip: none;
            text-decoration: underline
        }
    
        .c32 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c7 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: center
        }
    
        .c22 {
            font-size: 13pt;
            color: #666;
            font-weight: 700
        }
    
        .c39 {
            background-color: #fff;
            max-width: 468pt;
            padding: 0 72pt 0 72pt
        }
    
        .c9 {
            orphans: 2;
            widows: 2
        }
    
        .c17 {
            height: 25.2pt
        }
    
        .c25 {
            height: 15.6pt
        }
    
        .c41 {
            height: 11pt
        }
    
        .c24 {
            height: 20.1pt
        }
    
        .c4 {
            height: 27.4pt
        }
    
        .c38 {
            height: 17.9pt
        }
    
        .c10 {
            height: 23.7pt
        }
    
        .c34 {
            height: 17.1pt
        }
    
        .c6 {
            height: 0
        }
    
        .c23 {
            height: 12.6pt
        }
    
        .c31 {
            font-weight: 700
        }
    
        .c36 {
            margin-right: -45pt
        }
    
        .c35 {
            height: 20.9pt
        }
    
    
        li {
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    
        p {
            margin: 0;
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    
    }
    
    .fertility_assessment_female {
        margin: auto;
        padding: 20px;

        .c38 {
            border-right-style: dotted;
            padding: 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 15pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c33 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 229.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c48 {
            border-right-style: solid;
            padding: 2pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 249pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c51 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c40 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 141.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c31 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 141.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c50 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 205.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c12 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 92.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c53 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 1pt;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 117pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c2 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #fff;
            border-top-width: 1pt;
            border-right-width: 0;
            border-left-color: #fff;
            vertical-align: top;
            border-right-color: #fff;
            border-left-width: 0;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 234pt;
            border-top-color: #fff;
            border-bottom-style: solid
        }
    
        .c34 {
            border-right-style: dotted;
            padding: 2pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 180pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c23 {
            border-right-style: dotted;
            padding: 2pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 117pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c37 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 92.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c16 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 87pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c52 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 227.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c27 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 87.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c5 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 88.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c28 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c10 {
            margin-left: 36pt;
            padding-top: 5pt;
            padding-left: 0;
            padding-bottom: 5pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-size: 10pt
        }
    
        .c10 .c24 {
            padding: 5pt
        }
    
        .c54 {
            -webkit-text-decoration-skip: none;
            color: #000;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c19 {
            margin-left: 18pt;
            padding-top: 12pt;
            padding-left: 0;
            padding-bottom: 5pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-size: 10pt
        }
    
        .c8 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }
    
        .c0 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal;
            line-height: 1.5;
        }
    
        .c21 {
            color: #434343;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c18 {
            color: #434343;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c4 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c13 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c1 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: right
        }
    
        .c20 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c35 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }
    
        .c47 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: .2;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c32 {
            color: #000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c29 {
            padding-top: 12pt;
            padding-bottom: 10pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }
    
        .c42 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c36 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c25 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c9 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: .5;
            text-align: left
        }
    
        .c3 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left
        }
    
        .c43 {
            background-color: #fff;
            max-width: 468pt;
            padding: 0 72pt 0 72pt
        }
    
        .c15 {
            font-size: 9pt;
            font-style: italic;
            color: #222
        }
    
        .c22 {
            margin-left: 72pt;
            padding-left: 0
        }
    
        .c17 {
            padding: 0;
            margin: 0
        }
    
        .c39 {
            height: 21pt
        }
    
        .c26 {
            height: 11pt
        }
    
        .c44 {
            font-size: 10pt
        }
    
        .c14 {
            height: 23.1pt
        }
    
        .c7 {
            height: 0
        }
    
        .c24 {
            font-weight: 700
        }
    
        .c46 {
            height: 24.3pt
        }
    
        .c30 {
            color: #434343
        }
    
        .c41 {
            margin-left: 72pt
        }
    
        .c55 {
            font-size: 13pt
        }
    
        .c49 {
            color: #666
        }
    
        .c11 {
            height: 22pt
        }
    
        .c6 {
            background-color: #fefefe
        }
    
        .c45 {
            height: 20.5pt
        }
    
        li {
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    
        p {
            margin: 0;
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
        
        .check_Yes:before, .check_Checked:before {
            content: "✔ "
        }
    
        .check_Yes, .check_Checked {
            font-weight: 700;
        }
    
        .check_No:before, .check_Not:before {
            content: "✕ ";
        }
    
        .other_diseases {
            font-weight: 700;
        }
    
        .tok {
            float: left;
        }
    
        .dt {
            float: right;
        }
    }
    
    .fertility_assessment_male {
        margin: auto;
        padding: 20px;

        .c38 {
            border-right-style: dotted;
            padding: 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 15pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c33 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 229.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c48 {
            border-right-style: solid;
            padding: 2pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 249pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c51 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c40 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 141.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c31 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 141.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c50 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 205.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c12 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 92.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c53 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 1pt;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 117pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c2 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #fff;
            border-top-width: 1pt;
            border-right-width: 0;
            border-left-color: #fff;
            vertical-align: top;
            border-right-color: #fff;
            border-left-width: 0;
            border-top-style: solid;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 234pt;
            border-top-color: #fff;
            border-bottom-style: solid
        }
    
        .c34 {
            border-right-style: dotted;
            padding: 2pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 180pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c23 {
            border-right-style: dotted;
            padding: 2pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 117pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c37 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #000;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: solid;
            border-bottom-width: 0;
            width: 92.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c16 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 87pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c52 {
            border-right-style: solid;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #000;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 227.2pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c27 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 87.8pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c5 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 88.5pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c28 {
            border-right-style: dotted;
            padding: 5pt 5pt 5pt 5pt;
            border-bottom-color: #b7b7b7;
            border-top-width: 0;
            border-right-width: 0;
            border-left-color: #b7b7b7;
            vertical-align: top;
            border-right-color: #b7b7b7;
            border-left-width: 0;
            border-top-style: dotted;
            border-left-style: dotted;
            border-bottom-width: 0;
            width: 162pt;
            border-top-color: #b7b7b7;
            border-bottom-style: dotted
        }
    
        .c10 {
            margin-left: 36pt;
            padding-top: 5pt;
            padding-left: 0;
            padding-bottom: 5pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-size: 10pt
        }
    
        .c10 .c24 {
            padding: 5pt
        }
    
        .c54 {
            -webkit-text-decoration-skip: none;
            color: #000;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c19 {
            margin-left: 18pt;
            padding-top: 12pt;
            padding-left: 0;
            padding-bottom: 5pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-size: 10pt
        }
    
        .c8 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }
    
        .c0 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c21 {
            color: #434343;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c18 {
            color: #434343;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c4 {
            color: #000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c13 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 9pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c1 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: right
        }
    
        .c20 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c35 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }
    
        .c47 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: .2;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c32 {
            color: #000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c29 {
            padding-top: 12pt;
            padding-bottom: 10pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }
    
        .c42 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: Arial;
            font-style: normal
        }
    
        .c36 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    
        .c25 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }
    
        .c9 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: .5;
            text-align: left
        }
    
        .c3 {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1;
            text-align: left
        }
    
        .c43 {
            background-color: #fff;
            max-width: 468pt;
            padding: 0 72pt 0 72pt
        }
    
        .c15 {
            font-size: 9pt;
            font-style: italic;
            color: #222
        }
    
        .c22 {
            margin-left: 72pt;
            padding-left: 0
        }
    
        .c17 {
            padding: 0;
            margin: 0
        }
    
        .c39 {
            height: 21pt
        }
    
        .c26 {
            height: 11pt
        }
    
        .c44 {
            font-size: 10pt
        }
    
        .c14 {
            height: 23.1pt
        }
    
        .c7 {
            height: 0
        }
    
        .c24 {
            font-weight: 700
        }
    
        .c46 {
            height: 24.3pt
        }
    
        .c30 {
            color: #434343
        }
    
        .c41 {
            margin-left: 72pt
        }
    
        .c55 {
            font-size: 13pt
        }
    
        .c49 {
            color: #666
        }
    
        .c11 {
            height: 22pt
        }
    
        .c6 {
            background-color: #fefefe
        }
    
        .c45 {
            height: 20.5pt
        }
    
        
    
        li {
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
    
        p {
            margin: 0;
            color: #000;
            font-size: 11pt;
            font-family: Arial
        }
        
        .check_Yes:before {
            content: "✔ ";
        }
    
        .check_Yes {
            font-weight: 700;
        }
    
        .check_No:before {
            content: "✕ ";
        }
    
        .other_diseases {
            font-weight: 700;
        }
    
        .tok {
            float: left;
        }
    
        .dt {
            float: right;
        }
    }
}

.v2.viewable {
    position: absolute;
    display: block !important;
    width: 100%;  
    justify-content: center;
    /*top: 60px;*/
    background-color: #0000007a;
    /*height: 100%;*/
    overflow: auto;
    body {
        width: 100%;
        padding: 0 10px;
        border: 1px solid #dddddd;
        box-shadow: 2px 4px 11px 2px #434343;
        margin: auto;
    }

    .custom {
        visibility: hidden;
    }

}

@media print {

    @page { size: portrait; }
    
    .htmlContent.v1 body {
        width:  595pt;
        height: 842pt;
        overflow: hidden;
        padding: 5pt 15pt !important;
    }

    .htmlContent.v1 {
		display: block;
		position: relative;
        top: 0;
        left: 0;
        margin-top: 80pt;
        .custom {
            bottom: 110pt;
            position: absolute;
        }
	}

    .htmlContent.v2 {
		display: block;
		position: relative;
        top: 0;
        left: 0;
        .custom {
            bottom: 110pt;
            position: absolute;
        }

	}

    .htmlContent.v2 body {
        width:  595pt;
        /*height: 842pt;*/
        /*overflow: hidden;*/
        padding: 5pt 5pt !important;
        overflow:  visible;
    }
    .page-break { page-break-inside: avoid; page-break-before: always; }

}