@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.doctor_patient_details_component {
    max-width: 840px;
    margin: 30px auto 0;
    width:100%;
    position: relative;
    @include flex(column,flex-start,center);
    & .patient_details_container_header{
        margin:10px 0;
        width:100%;
    }
    & .patient_details {
        @include flex(row,flex-start,flex-start);

        flex-wrap: wrap;
        & .patient_profile {
            width:400px;
            padding: 0 15px 0 0;
        }
        & .patient_appointments {
            width:300px;
            height:300px;
        }
    }
    & .patient_details_tab {
        @include flex(row,center,center);
        max-width: 800px;
        width:100%;
        padding: 30px 0;
    }

    @media screen and (max-width:720px){
        & .patient_details_container_header{
            margin:10px 0;
            width:inherit;
            & a{
                & svg{
                    margin:0 0 0 10px;
                }
            }
        }
        @include flex(column,center,center);
        & .patient_details {
            @include flex(column,center,center);
            width:95%;
            & .patient_profile {
                width:100%;
                margin:0 0 30px 0;
                padding: 0;
                & .patient_bottomElement{
                    position:fixed;
                    @include flex(row,center,center);
                    height:50px;
                    width:100%;
                    bottom:0;
                    left:0;
                    background-color: $nav-background;
                    z-index:1;
                }
            }
            & .patient_appointments {
                width:100%;
                height:300px;
            }
        }
        & .patient_details_tab {
            width:95%;
            @include flex(column,center,center);
            padding: 30px 0;
        }
    }
    @media (min-width:721px) and (max-width:860px){
        @include flex(column,center,center);
        & .patient_details_container_header{
            & a{
                & svg{
                    margin:0 0 0 10px;
                }
            }
        }
        & .patient_details_tab {
            width:95%;
            @include flex(column,center,center);
        }
    }
}