@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.caremanager {
    & .add-caremanager {
        & .addCaremanagerContainer {
            padding: 25px 30px 20px 30px;
            margin: 0 auto;

            & .title {
                font-size: 20px;
                font-weight: 500;
                margin: 0;
                padding: 0;
            }
        }
        & .addCaremanager-form {
            padding: 0 0 30px 0;
            
            @media (max-width:480px) {
                & .bottomElement {
                    position: fixed;
                    @include flex(row, center, center);
                    height: 60px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: $nav-background;
                    z-index: 1;
                }
            }   
            & .form-element {
                margin: 0 0 15px 0;
                @include flex(column,flex-start,space-between);
                & .yes_no_div {
                    padding: 5px;
                    @include flex(row,space-between,center);
                    &  #yes, & #no {
                        margin: 0;
                    }
                    & label {
                        margin: 0;
                        padding: 0 0 0 8px;
                    }
                }
            }
            & label {
                margin: 0 0 10px 0;
                font-size: 12.8px;
                font-weight: 400;
            }

            .inputField {
                padding: 8px 0 8px 8px;
                width: 200px;
            }
            & select {
                color: rgb(117, 116, 116);
                & option {
                    color: black;
                }
            }
            
        }
    }
    .invalid {
        border: 2px solid #ff7a7a;
        background-repeat: no-repeat;
        background-position: 95% 50%;
    }    
    .field-error {
        display: none;
        font-size: 0.85em;
        align-self: flex-end;
        position: relative;
        margin: 0 50px 10px 0;
        color: #ff7a7a;
    }
}