@import './../../../styles/mixinflex'; 
@import './../../../styles/variables';

.home-container {
    
    // @include flex(column,flex-start,space-evenly);
    & .tab-content {
        
        padding: 30px 55px 35px 55px;
        & .content-header {
            margin: 0 0 40px 0;
            @include flex(row,flex-start,center);
            & .search-container {
                width: fit-content;
                position: relative;
                margin: 0 35px 0 0;
                & .search-field {
                    height: 34px;
                    width: 200px;
                    padding: 0 10px;
                    border: 1px solid $border-color;
                    border-radius: $button-borderradius;
                }
                & .search-image {
                    cursor: pointer;
                    width: $image-width;
                    height: $image-height;
                    right: 5px;
                    position: absolute;
                    top: 8px;
                }
            }
            & .filter-container {
                margin: 0 20px 0 0;
                & .filter {
                    padding: 5px;
                    color: $text-light-color;
                    border: 1px solid $border-color;
                    border-radius: $button-borderradius;
                    margin:10px 0 8px 0 ;
                    background-color: $white-color;
                    cursor: pointer;
                    @include flex(row,center,center);
                    & .filter-image {
                        float: left;
                        width: $image-width;
                        height: $image-height;
                        padding: $image-padding;
                    }
                }
                .select-content {
                    @include dropdown;
                }
            }
            & .add-container {
                margin-left: auto;
                & .add-button {
                    padding: 5px;
                    color: $text-light-color;
                    border: 1px solid $border-color;
                    border-radius: $button-borderradius;
                    margin: $button-margin;
                    background-color: $white-color;
                    cursor: pointer;
                    @include flex(row,center,center);
                    & .add-image {
                        width: $image-width;
                        height: $image-height;
                        padding: $image-padding;
                    }
                }
                .select-user {
                    @include dropdown;
                }
            }
        }
    }
    .tab-content a {
        text-decoration: underline;
    }
}


