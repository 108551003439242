@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.CareManager_patient_details_component {
    & .patient_details_container {
        max-width: 840px;
        width: 100%;
        @include flex(column,flex-start,center);
        margin: 30px auto 0;
        & .patient_details {
            width: inherit;
            padding: 20px 0 0 0;
            @include flex(row,flex-start,flex-start);
            flex-wrap: nowrap;
            & .patient_profile {
                width: 60%;
                padding: 0 15px 0 0;
            }
            & .patient_appointments {
                width:40%;
            }
        }
        & .patient_details_tab {
            max-width: 800px;
            width: 100%;
            padding: 30px 0;
        }
    }
}