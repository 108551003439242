@import './../../../../../styles/mixinflex';
@import './../../../../../styles/variables';

.patientHome {
    & .patient_row_component {
        width: 100%;
        max-width: 800px;
        margin: 30px auto;
        & .toolContainer{
            @include flex(row,space-around,center);
            flex-wrap: nowrap;
            width: 60%;
            margin: 50px auto 30px;

            & .form-element {
                cursor: pointer;
                & input {
                    position: relative;
                    width:100% ;
                    font-size:$medium-font;
                    padding:8px 10px;
                    border: 1px solid $border-color;
                    border-radius: $button-borderradius;
                }

                #default_date_picker::-webkit-calendar-picker-indicator {
                    display: block;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }

                #new_date_picker::-webkit-calendar-picker-indicator {
                    background: none;
                    content: url(./../../../../../images/calendarDone.png);
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    @media screen and (max-width:480px) {
        & .patient_row_component {
            & .toolContainer{
                width: 100%;
                margin: 0 auto 30px;
                padding: 0 10px;
                & .form-element {
                    width: 40%;
                }
            }
        }
    }
}

