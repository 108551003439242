@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.header-counter {
    // margin: 40px auto 70px;
    flex:1;
    margin:10px;
    padding:10px;
    & .headerCounterBox {
        border: 1px solid $border-color;
        // margin: 10px;
        // width:100%;
        // height: 95px;
        & .headerCounterDetails {
            padding: 20px;
           @include flex(column,flex-start,center);
            & .headerCount {
               font-family: $font-family;
               font-size: $larger-font;
               font-weight: $font-weight-bold;
               color: $text-dark-color;
               margin: 0;
            }
            & .headerCountTitle {
                font-size: $small-font;
                font-weight: $font-weight-regular;
                color: $text-light-color;
                padding: 10px 0 0 0;
                margin: 0;
            }
        }
    }
}