@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.time_line {
    @include flex(row,flex-start,flex-start);
    & .timeline_circle {
        align-content: flex-start;
        @include flex(column,center,center);
        & .outer_circle {
            width: 26px;
            height: 26px;
            background-color: $hover-color;
            border-radius: $statusBtn-borderradius;
            & .inner_circle {
                width: 12px;
                height: 12px;
                background-color: $primary-color;
                border-radius: $statusBtn-borderradius;
                margin: 0 auto;
                position: relative;
                top: 7px;
            }
        }
        & .timeline_line {
            width: 0;
            height: 150px;
            border: 1px solid $primary-color;
            margin: 0;
        }
    }
    & .timeline_content_box {
        max-width: 300px;
        width:80%;
        height: 150px;
        border: 1px solid $border-color;
        border-radius: $card-borderradius;
        margin: 0 0 0 12px;
    }
}