@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.caremanager-modal {
    & .container {
        padding: 20px 0 !important;
        @include formStyles;
    }
    & .add-caremanager {
        & .addCaremanagerContainer {
            padding: 0 0 30px 0;
            margin: 0;
            @include flex(row,space-between,center);

            & .title {
                font-size: $large-font;
                font-weight: $font-weight-medium;
                margin: 0;
                padding: 0;
            }
        }
    }
    .invalid {
        border: 2px solid #ff7a7a;
        // background-image: url(./../images/icon-error.svg);
        background-repeat: no-repeat;
        background-position: 95% 50%;
    }    
    .field-error {
        display: none;
        font-size: $tiny-font;
        align-self: flex-start;
        position: relative;
        margin: 3px 0 0 0;
        color: #ff7a7a;
    }
}