@import './../../styles/mixinflex';
@import './../../styles/variables';

.get_otp {
    width: 450px;
    height: 410px;
    border: 1px solid $border-color;
    @include flex(column,center,flex-start);
    margin: 120px auto 0;
    & .logo {
        width: 70px;
        padding: 80px 0 20px;
        & .logo_image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .login-btn {
        width:250px;
        background-color: $primary-color;
        color: $white-color;
        font-size: $small-font;
        font-weight: $font-weight-medium;
        padding: 15px 0;
        text-align: center;
        margin: 15px auto 0;
    }

    & .back_btn {
        width:250px;
        text-align: left;
        padding-top: 20px;

        & .link {
            color: $text-light-color;
        }
    }

    .otp_fields {
        margin: 0 0 20px 0;
    }
    .get_otp_input {
        width: 32px;
        height: 32px;
        text-align: "center";
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
    }
    .otp_timer, .reSend-btn {
        font-size: $small-font;
        font-weight: $font-weight-regular;
        color: $text-dark-color;
    }
    .reSend-btn {
        color: $primary-color;
        border: $button-border;
        background-color: $white-color;
        padding: 0;
    }

    .reSend-btn:disabled {
        color: $disabled-color;
    }

    .otp_timer_and_resend_container{
        width:220px;
    }
    @media screen and (max-width:480px){
        width:100%;
        border: none;
    }
}
