@import './../../styles/mixinflex';
@import './../../styles/variables';

.table-container {
    width: inherit;
    padding: 0;
    & table, & th, & td {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-collapse: collapse;
    }
    & th, & td {
        border-left: 1px solid $border-color;
    }
    & th:first-child {
        border-left: 0px;
    }
    & td:first-child {
        border-left: 0px;
    }
    #table_data {
        border-spacing: 0px;
        text-align: center;
        font-family: $font-family;
        width: 100%;
        & tbody {
            
            & tr {
                
                & th {
                    font-size: $small-font;
                    font-weight: $font-weight-medium;
                    padding: 17px 0 17px 15px;
                    text-align: left;
                    color: $text-dark-color;
                    text-transform: capitalize;
                }
                & td {
                    font-size: $small-font;
                    font-weight: $font-weight-medium;
                    color: $text-light-color;
                    text-align: left;
                    padding: 17px 0 17px 15px;
                }
            }
        } 
        .data-row {
            background-color: white;
            &:hover {
                outline: 1px solid;
                background-color: #FAFBFC;
            }
        }
    }
    & .noDataDiv {
        width: inherit;
        margin: 0 auto;
        @include flex(row,center,center);
        & .noDataContent {
            text-align: center;
            @include noData;
        }
    }
}

.table_profileContainer {
    width:350px;
    & .table_profileHeader {
        width: 100%;
        margin: 10px 0 20px 0;
        @include flex(row,space-between,center);
        & .edit-btn {
            @include flex(row,center,center);
            img .edit-image {
                width: $image-width;
                height: $image-height;
            }
        }
    }
}

