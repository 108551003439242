@import './../../styles/mixinflex';
@import './../../styles/variables';

.search-container {
    width: 100%;
    position: relative;
    margin: 0 35px 0 0;
    & .searchField {
        height: 40px;
        width:inherit;
        padding: 0 10px;
        border: 1px solid $border-color;
        border-radius: $button-borderradius;
    }
    & .search-image {
        cursor: pointer;
        width: $image-width;
        height: $image-height;
        right: 5px;
        position: absolute;
        top: 8px;
    }
    .suggestionBox {
        // @include dropdown;
        margin: 5px 0 0 0;
        //max-height: 142px;
        padding:10px;
        height: auto;
        width: 100%;
        overflow-y: auto;
        overflow-x:hidden;
        position: absolute;
        background-color: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        @include flex(column,flex-start,center); 
        flex:1;
        flex-wrap: nowrap;
        // padding: 10px 0;
        & .searchSuggestion{
            padding:6px 6px 6px 10px;
            border: 1px solid $white-color;
            @include flex(column,flex-start,center);
            width:93%;
            margin:0 auto;
            & .searchValue{
                font-size: $medium-font;
            }
            & .searchType{
                font-size: $small-font;
                color: $text-light-color ;
                font-style: italic;
            }
            &:hover{
                background-color:$nav-background;
                border: 1px solid $border-color;
                cursor:pointer;
            }
            
        }
        & .noDataFound{
            padding:10px
        }
    }
    @media screen and (max-width:480px){
        & .searchField{
            max-width: 100%;
            width:100%;
        }
    }
}