@import './../../../../../../styles/mixinflex';
@import './../../../../../../styles/variables';


.search {
    @include flex(column,center,center);
    
    & .searchInput {
        @include flex(row,space-around,center);
        flex-wrap: nowrap;
        width: 100%;
        margin: 50px auto 30px;
        
        & .form-element {
            cursor: pointer;
            & input {
                position: relative;
                width:100% ;
                font-size:$medium-font;
                padding:8px 10px;
                border: 1px solid $border-color;
                border-radius: $button-borderradius;
            }

            @media screen and (max-width:480px) {
                /*& input[type=date] {
                    text-align: right;
                }
                
                & input[type="date"]:before {
                    color: lightgrey;
                    content: attr(placeholder) !important;
                    margin-right: 0.5em;
                }
                
                /*& input[type="date"]:focus:before {
                    content: '' !important;
                }*/
            }

            #default_date_picker::-webkit-calendar-picker-indicator {
                display: block;
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
            

            #new_date_picker::-webkit-calendar-picker-indicator {
                background: none;
                content: url(./../../../../../../images/calendarDone.png);
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }
    }
    & .patientRow {
        width: 100%;
        max-width: 700px;
    }
}