@import './../../../styles/mixinflex';
@import './../../../styles//variables';

.successMsgDiv {
    max-width: 450px;
    width: 100%;
    margin: 80px auto 50px;
    text-align: center;
    & .successMsg {
        font-size: $medium-font;
        line-height: 2;
        font-weight: $font-weight-semibold;
        color: $button-success-primary;
    }

    & span {
        font-style: italic;

        & .counter {
            color: brown;
        }
    }


}