@import './../../styles/mixinflex';
@import './../../styles/variables';

.program {
    background-color: $white-color;
    max-width:840px;
    width: 100%;
    margin:0 auto;
    & .programHeader {
        padding: 30px 20px;
        @include flex(row,space-between,center);
        & .programHeading {
            font-size: $large-font;
            font-weight: 600;
            color: $text-dark-color;
            margin: 0;
        }
        & .addProgramButton {
            text-decoration: none;
            font-size: $small-font;
            background-color: $primary-color;
            color: $white-color;
            padding: 10px 12px;
            border: $button-border;
            border-radius: $button-borderradius;
        }
    }
    & .programsCardContainer {
        height: 80vh;
        overflow-y: auto;
        @include flex(column,flex-start,flex-start);
        align-content: flex-start;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0 20px 0;
        &::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
    @media screen and (max-width:480px){
        & .programHeader {
            & .programHeading {
                font-size: $medium-font-2;
            }
        }  
    }
}