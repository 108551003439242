@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.lead_patient_profile_container {

    & .buttonWrapper {
        padding: 10px;

        & button.callToActionBtn {
            margin: auto;
        }
    }
}

.Patient_profile_container {
    width:80%;
    border: 1px solid $border-color;
    padding: 20px;
    margin: 0 auto;
    position:relative;

    & .patient_profile_header {
        @include flex(row,space-between,center);
        & .patient_name {
            font-size: $large-font;
            font-weight: $font-weight-semibold;
            color: $text-dark-color;
            margin: 0;
        }
        & .patient_status {
            font-size: $small-font;
            font-weight: $font-weight-medium;
            color: $text-success;
            background-color: $bg-success;
            border-radius: $statusBtn-borderradius;
            padding: 4px 8px;
            margin: 0;
            @include flex(row,flex-start,center);
            & .activeDot {
                width: 8px;
                height: 8px;
                background-color: $text-success;
                border-radius: $statusBtn-borderradius;
                margin: 0 5px 0 0;
            }
            & .newDot {
                width: 8px;
                height: 8px;
                background-color: $primary-color;
                border-radius: $statusBtn-borderradius;
                margin: 0 5px 0 0;
            }
        }
        & .New {
            color: $primary-color;
            background-color: $hover-color;
        
        }
    }


    & .assistant_patient_details_container {
        @include flex(row,space-between,center);
        width:100%;
        // padding: 15px 0;
        margin: 20px 0 20px 0;
        & .assistant_patient_details_block{
            @include flex(column,flex-start,flex-start);
            & .patient_heading{
             font-size: $small-font;
             color:$text-light-color; 
             font-weight: $font-weight-regular;  
            }
            & .patient_value{
             font-size: $small-font;
             color:$text-dark-color; 
             font-weight: $font-weight-medium;    
            }
        }
    }
    @media (max-width:650px){
        & .assistant_patient_details_container {
            @include flex(column,flex-start,center);
            & .assistant_patient_details_block{
                width:100%;
                @include flex(row,space-between,center); 
                margin:8px 0;  
            }
        }
        
    }
}
.assistant_payment_options_container{
    width:350px;
    & .patient_payment_container{
        border: 1px solid $border-color;
        padding:10px;
        margin:10px 0;
        font-size: $medium-font;
        @include flex(row, space-between, center);
        & .patient_payment_block{
          width:100%;
          @include flex(row, space-between, center);
          & .patient_payment_content{
            @include flex(row, flex-start, center);
            & .patient_pays{
              color:$text-light-color
            }
            & .patient_pays_amount{
              margin: 0 0 0 5px;
              color:$text-dark-color;
              font-weight:$font-weight-bold;
            }
          }
        }
        & .discount_input_container{
            margin:2px 0 8px;
            width:100%;
            @include flex(row, flex-start, flex-end);
              & .discount_input{
                width:90px;
                & label{
                  display:block;
                  font-size:$small-font;
                  color:$text-light-color;
                  margin:0 0 2px 0;
                }
                & input{
                  width:100% ;
                  font-size:$medium-font;
                  padding:10px 0 10px 8px;
                  border: 1px solid $border-color;
                  border-radius: $button-borderradius;
                }
              }
              & button{
                width:10%;
                width:fit-content;
                margin:0 0 0 20px;
                height:40px;
              }
        }
      }
      & .payment_option_container {
        margin:10px 0 0 0;
        & .payment_option_title {
          font-size: $medium-font;
          font-weight: $font-weight-medium;
          margin: 0 0 10px 0;
        }
        & .radioButtonGroup {
            width:60%;
            margin:0 0 10px 0;
            @include flex(row,flex-start,center);
            flex-wrap: nowrap;
            & label{
                width:100%;
                border:1px solid $border-color;
                cursor:pointer;
                padding:10px;
                margin:0;
                & input[type='radio']{
                    cursor:pointer;
                    width:fit-content;
                    margin:0 10px 0 0;
                }
                &:hover{
                    background-color:$hover-color;
                }
            }
           
        }
    }
    & .continue_btn{
        @include flex(row,flex-end,center);
    }
    & .assistant_qr_container{
        @include flex(column,flex-start,center);
        & .header{
            @include flex(row,space-between,center);
            width:100%;
        }
        & .qr_code{
            margin:15px auto;
        }
        & .qr_content{
            margin:0 auto;
        }
    }
  
}
@media screen and (max-width:500px){
    .assistant_payment_options_container{
       width: 100%;
        &  .form-element{
            & h3{
                font-size: $large-font;
                font-weight:$font-weight-semibold;
                margin:0 0 15px 0;   
            }
            & .radioButtonGroup{
                width:100%;
            }
        }
        & .continue_btn{
            margin:20px 0 0 0;
            @include flex(row,flex-end,center);
            & >*{
                width:100% ;
            }
        }
    }
}
