@import './../../styles/mixinflex';
@import './../../styles/variables';

.upcoming_appointments_widget_container {
    width:100%;
    height:100%;
    border: 1px solid $border-color;
    & .appointments_heading {
        font-size: $medium-font;
        font-weight: $font-weight-semibold;
        color: $text-dark-color;
        margin: 15px;
    }
    & .appointments_container {
        & .appointment {
            margin:0 15px;
            border:1px solid $border-color;
            & .appointment_row1 {
                padding:10px;
                @include flex(row,space-between,flex-start);
                & .appointment_date_and_day{
                    @include flex(row,flex-start,center);
                    & .appointment_date {
                        font-size: $small-font;
                        font-weight: $font-weight-semibold;
                        color: $text-dark-color;
                        padding: 0 8px 0 0;
                    }
                    & .appointment_day {
                        font-size: $tiny-font;
                        font-weight: $font-weight-medium;
                        color: $text-light-color;
                        margin: 0;
                    }
                }
            }
            & .appointment_row2 {
                padding: 8px 10px 10px;
                & .appointment_time {
                    font-size: $tiny-font;
                    font-weight: $font-weight-medium;
                    color: $text-light-color;
                }
            }
        }
    }
    & .seeMore_btn {
        position: absolute;
        bottom: 15px;
        width: 90%;
        height:27px;
        margin:0 15px;
        border: $button-border;
        background-color: $hover-color;
        color: $primary-color;
        font-size: $small-font;
        font-weight: $font-weight-medium;
    }
}