@import './../../styles/mixinflex';
@import './../../styles/variables';

.support-container{
    border:1px solid $border-color;
    max-width:450px;
    margin:45px auto 0;
    padding:25px;
    & .support-header{
        @include flex(row,flex-start,center);
        & .title{
            margin:0 0 0 10px;
            font-weight:$font-weight-semibold;
            font-size:$large-font;
            color: $text-dark-color;
        }
        margin:0 0 20px 0;
    }
    & .support-content{
        width: 100%;
        @include flex(column,flex-start,flex-start);
        & label{
            margin:0 0 16px 0;
            color:$text-light-color;
        }
        & textarea{
            width:97%;
            border:1px solid $border-color;
            padding:5px;
            resize: vertical;
        }
        & .btn-holder{
            width:inherit;
            margin:20px 0 0 0;
            @include flex(row,flex-end,flex-start);
        }
    }
    @media screen and (max-width:570px){
        border:none;
        padding:30px 16px;
        & .support-header {
            & .title {
                margin:0 0 0 10px;
                font-size: $medium-font-2;
            }
        }
        margin:0 auto;
    }
}