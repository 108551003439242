@import './../../styles/mixinflex';
@import './../../styles/variables';

.upcoming_appointments_container {
    width:80%;
    overflow-y: scroll;
    height: 216px;
    position: relative;
    padding: 0 20px 20px;
    margin: 0 auto 30px;
    & .appointment_header {
        @include flex(row,space-between,center);
        position: sticky;
        top:0;
        padding: 20px 0;
        background-color: $white-color;
        & .appointments_heading {
            font-size: $medium-font;
            font-weight: $font-weight-semibold;
            color: $text-dark-color;
        }
    }
    & .appointments_container {
        & .appointment {
            & .appointment_row {
                border: 1px solid $border-color;
                padding: 20px;
                margin:0 0 10px 0;
                @include flex(row,space-between,center);
                & .appointment_date_day_time{
                    @include flex(row,flex-start,center);
                    & .appointment_date {
                        font-size: $small-font;
                        font-weight: $font-weight-semibold;
                        color: $text-dark-color;
                        padding: 0 25px 0 0;
                    }
                    & .appointment_day {
                        padding: 0 25px 0 0;
                    }
                    & .appointment_day, & .appointment_time {
                        font-size: $tiny-font;
                        font-weight: $font-weight-medium;
                        color: $text-light-color;
                        margin: 0;
                    }
                }
                @media screen and (max-width:560px){
                    & .appointment_date_day_time{
                        @include flex(column,flex-start,flex-start);
                        & .appointment_date {
                            padding: 0 0 10px 0;
                        }
                        & .appointment_day {
                            padding: 0 0 5px 0;
                        }
                        & .appointment_day, & .appointment_time {
                            font-size: $tiny-font;
                            font-weight: $font-weight-medium;
                            color: $text-light-color;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    & .seeMore_btn {
        position: absolute;
        bottom: 15px;
        width: 90%;
        height:27px;
        margin:0 15px;
        border: $button-border;
        background-color: $hover-color;
        color: $primary-color;
        font-size: $small-font;
        font-weight: $font-weight-medium;
    }
    @media screen and (max-width:480px){
        padding: 0 16px 16px;
    }
}
