@import './../../styles/_mixinflex.scss';
@import './../../App.scss';
@import './../../styles/variables';
.sidebar {
    height: 100vh;
    background-color: $nav-background;
    @include flex(column,center,flex-start);
    .logo {
        width: 50px;
        height: 50px;
        margin: 11px auto 11px 20px;
        & img {
            border-radius: 50px;
            height: auto;
        }
    }
    .nav-links {
        padding: 0;
        margin: 0;
        list-style-type: none;
        width: 90%;

        .nav-items {
            margin: 0;
            & a {
                padding: 10px 0 10px 20px;
                margin: 0;
                font-family: $font-family;
                text-decoration: none;
                font-size: $medium-font;
                color:$text-light-color;
                @include flex(row,flex-start,center);
                & svg{
                    margin: 0 10px 0 0;
                    & path{
                        stroke:$text-light-color
                    }   
                }
                &:hover  {
                    background-color: $hover-color;
                    color: $primary-color;
                    & svg{
                        & path{
                            stroke:$primary-color
                        }   
                    }

                }
            }
            & .active-item {
                background-color: $hover-color;
                color: $primary-color;
                & svg{
                    margin: 0 10px 0 0;
                    & path{
                        stroke:$primary-color
                    }   
                }             
            }
        }
        
    }
    
}
