@import './../../styles/mixinflex';
@import './../../styles/variables';

.programContentNavigation {
    width: 250px;
    height: 130px;
    background-color: white;
    margin: 0 30px 0 35px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    & .contentList {
        list-style-type: none;
        padding: 10px;
        margin: 0;
        & a {
            text-decoration: none;
            color:  black;
            & li { 
                margin: 0;
                background-color: #FBFBFB;
                padding: 12px 0 12px 15px;
                border-bottom : 2px solid #FBFBFB;
                cursor: pointer;
                @include flex(row,flex-start,center);
                &:first-child {
                    margin: 0 0 10px 0;
                }
                & .one-image, & .two-image {
                    width: 25px;
                    height: 25px;
                    padding: 0 15px 0 0;
                }
                & .add-image {
                    width: $image-width;
                    height: $image-height;
                    padding: 0 0 0 80px;
                    cursor: pointer;
                }
            }
            & .activeListItem {
                border-bottom: 2px solid black;
            }
        }
    }
}