@import './styles/mixinflex';
@import './styles/variables';

*{
    box-sizing: border-box;
}
body{
    margin: 0;
    font-family: $font-family;
}

.grid-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 100vh;
    grid-template-areas: "sidenav main";
    height: 100vh;
    & .sidenav {
        grid-area: sidenav;
    }
    & .main {
        background-color: $white-color;
        grid-area: main;
    }
    & .secondaryMain {
        width: 100vh;
        height: 100vh;
    }
}

#response {
    position: fixed;
    bottom: 20vh;
    width: 100%;
    z-index: 1000;
    text-align: center;
    
    & .busyIcon {
        margin: auto;
        width: 100px;
    }
}

img{
    width: 100%;
    height: 100%;
 
}

a{
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p{
    margin:0 ;
}

button {
    cursor: pointer;
    border: none;
}

input {
    font-size: $medium-font;
}

.divider{
    margin:10px 0;
}
.logout{
    & a{
        color:$primary-color;
        &:hover{
            text-decoration: underline;
        }
    }
}

.allScreenAndroidPopup {
    background-color: $primary-color;
    margin: 0 auto 0;
    padding: 10px;
    border-radius: 2px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%; 
    position: fixed;
    bottom:0;
    z-index:10;

    & .popup-section {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: center;
        margin: 10px auto;

        & .popup-logo {
            width: 50px;

            & .logo {
                margin: 0 5px 0 0;
            }
        }

        & .popup-message {
            color: white;
            font-size: 18px;
            font-weight: 450;
            margin: 0 0 10px;
            padding: 0 5px 0 10px;
        }
        & .popup-message {
            & .share {
                position: relative;
                top: 14px;
                margin: 0 5px;
            }
        }
        
        & .popup-close-icon {
            border: none;
            
        }
    }

    & .installBtnDiv {
        
        & .installBtn {
            margin: 0 auto 5px;
            background-color: #fff;
            color: #3ab5fb;
            padding: 6px 12px;
            font-size: 1rem;
            border-radius: 5px;
        }
    }
}