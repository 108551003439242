@import './../../styles/_mixinflex';
@import './../../styles/variables';

.Tabcontainer{
    width: 100%;
    border-bottom: 2px solid $border-color;
    & ul{
        list-style-type: none;
        margin: 0 0 0 41px;  
        padding: 0;  
        @include flex(row,flex-start,flex-start);

        li{
            padding: 15px;
            font-size: $small-font;
            color: $text-light-color;
            font-weight: $font-weight-medium;
            cursor: pointer;
            // &:hover{
            //     background-color: aqua;
            // }
        }
    }
    .active{
        border-bottom: 2px solid $primary-color;
        color: $text-dark-color;
    }
    .centerTab {
        @include flex(row,center,center);
        margin: 0;
    }
    .leftTab {
        @include flex(row,flex-start,flex-start);
        margin: 0;
    }
    .rightTab {
        @include flex(row,flex-end,flex-end);
        margin: 0;
    }
}
