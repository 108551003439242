@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.mediaEditorContainer {
    width: 750px;
    padding: 15px 25px;
    background-color: $white-color;
    border-radius: $card-borderradius;
    border: 1px solid $border-color;
    padding: 25px;
    & .deleteSection {
        display: flex;
        justify-content: flex-end;
        & .maximize-image {
            padding: 0 30px 0 0;
        }
        & .bin-image, & .maximize-image {
            width: $image-width;
            height: $image-height;
            margin: 0 0 15px;
            cursor: pointer;
        }
    }
}