@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.addModuleForm {
    width: 600px;
    background-color: $white-color;
    border: 1px solid $border-color;
    border-radius: $card-borderradius;
    padding: 30px;
    margin: 40px auto 25px;
    .relative_element{
        position:relative;
    }
    & .form-element {
        @include flex(column,flex-start,flex-start);
        & label {
            font-size: $small-font;
            font-weight: 400;
            color: $text-dark-color;
        }
        & #module-name {
            width: 90%;
            height: 40px;
            margin: 10px 0 20px 0;
            padding: 0 0 0 15px;
            border: 1px solid $border-color;
        }
        & #module-description {
            width: 90%;
            height: 100px;
            margin: 10px 0 30px 0;
            padding: 10px 15px;
            border: 1px solid $border-color;
        }
        & .unlock-content {
            margin: 10px 0 0 0;
            @include flex(row,flex-start,flex-start);
            & #unlock-duration {
                width: 50px;
                height: 30px;
                padding: 0 0 0 15px;
                margin: 0 15px 0 0;
                border: 1px solid $border-color;
            }
            & #select-unlockContent {
                width: 200px;
                height: 30px;
                padding: 5px 10px;
                border: 1px solid $border-color;
                background-color: $white-color;
                & option {
                    font-size: $small-font;
                    padding: 6px;
                    color: $text-light-color;
                    &:hover {
                        background-color: $hover-color;
                        color: $primary-color;
                    }
                }
            }
        }
        & .addModuleContentHeader {
            width: 100%;
            margin: 0 0 30px 0;
            @include flex(row,space-between,center);
            & .moduleContent {
                font-size: $medium-font;
                margin: 0;
            }
            & .saveContentBlock-btn {
                padding: 6px 12px;
                margin: 0 30px 0 0;
                background-color: white;
                border: 1px solid #eceff4;
                border-radius: $button-borderradius;
            }
     
            
        } 

        //  .main-nav {
        //     position: relative;
        // }

        //  .main-nav {
        // background: #fff;
        // height: 80px;
        // z-index: 150;
        // margin-bottom: -80px;
        // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
        // }

        //  .main-nav-scrolled {
        //     background-color: red;
        //     outline:solid;
        //     // width: 100%;
        //     top: 0;
        //     position:absolute;
        // }
    
        & .addModuleBlock-btn {
            padding: 6px 12px;
            background-color: #eceff4;
            border: $button-border;
            border-radius: $button-borderradius;
        }
    }
    & .modulesSave-button {
        width:100%;
        margin: 20px 0 0 0;
        @include flex(row,flex-end,center);
    }
}