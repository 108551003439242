    @import './../../styles/mixinflex';
@import './../../styles/variables';

.programCard {
    height: 150px;
    max-width: 700px;
    width:99%;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: $card-borderradius;
    /*@include flex(column,flex-start,center);*/
    font-family: $font-family;
    margin: 0 0 15px 0;
    cursor: pointer;
    & .programCardContent {
        padding: 20px 20px;
        & .cardHeader {
            @include flex(row,space-between,center);
            & .programTitle {
                margin: 0;
                font-size: $small-font;
                font-weight: 500;
                color: $text-dark-color;
            }
            & .editProgramButton {
                text-decoration: none;
                font-size: $medium-font;
                font-weight: 500;
                color: $primary-color;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        & .programDescription {
            margin: 10px 0 0 0;
            font-size: $small-font;
            color: $text-light-color;
            width: 80%;
        }
        & .Count {
            margin: 15px 0 0 0;
            @include flex(row,space-between,center);
            & .modules, & .clients {
                @include flex(row,space-between,center);
                & .modulesTitle, & .clientsTitle {
                    margin: 0;
                    font-size: $tiny-font;
                    font-weight: 400;
                    color: $text-light-color;
                    padding: 0 10px 0 0;
                }
                & .modulesCount, & .clientsCount {
                    margin: 0;
                    font-size: $small-font;
                    font-weight: 600;
                    color: $text-light-color;
                }
            }
        }
    }
    @media screen and (max-width:480px){
        & .programCardContent {
            padding:15px 10px;
            & .cardHeader {
                & .programTitle {
                    margin: 0;
                    font-size: $small-font;
                }
            }
        }
    }
}