@import './../../styles/mixinflex';
@import './../../styles/variables';

.clinicQRCode {
    margin: 50px auto;
    text-align: center;

    & .qrControls{
        & button {
            background-color: #e6e6e6;
            margin: 5px !important;
            border-radius: 10px 10px;
            display: inline-block;
            border: 1px solid #c5c5c5;
            padding: 5px 25px !important;
            box-shadow: 2px 2px 5px 0px #e2e2e2;
        } 
    }
}