@import './../../styles/mixinflex';
@import './../../styles/variables';

.selfcheckinIntro {
    margin: 30px auto;
    max-width: 450px;
    border: 1px solid $border-color;
    .container {
        @include formStyles;
        .selfCheckin-logo {
            width:60px;
            height:55px;
            margin: 10px auto 30px;
        }
        .selfCheckin-form {
            .healthConditionsMultipleCheck {
                margin: 0 0 30px 0;
                .healthConditionsMultipleCheckTitle {
                  font-size: $medium-font-2;
                  font-weight: $font-weight-semibold;
                  padding: 0 0 10px 0;
                } 
                .form-element {
                    margin: 0 0 3px 0;
                    @include flex(row,space-between,center);
                    .checkboxGroup {
                        width: 50%;
                        margin: 10px 0 0 0;
                        @include flex(row,flex-start,center);
                        flex-wrap: nowrap;
                        & > *{
                          width: fit-content;  
                        }
                        label {
                            margin: 0;
                            padding: 0 0 0 5px;
                        }
                    }
                }
            }
        }
        .booking-success {
            text-align: center;

            h2 {
                color: green;
                margin-bottom: 10px;
            }
            h2:before {
                content: "✓ ";
            }
        }
    }
}

#mediaUpload {
    width: 100%; 
}

@media (max-width: 480px) {

    .selfcheckinIntro {
        border: none;
    }
}