@import './../../styles/mixinflex';
@import './../../styles/variables';

.program-details {
    max-width: 840px;
    padding:16px;
    width:100%;
    margin: 0 auto;
    & .programDetails {
        border: 1px solid $border-color;
        padding: 15px 18px;
        margin: 0 0 10px 0;
        @include flex(column,flex-start,flex-start);
        & .programHeading {
            padding: 0 0 15px 0;
            margin: 0;
            font-size: $small-font;
            font-weight: 400;
            color: $text-light-color;
        }
        & .programTitle {
            margin: 0;
            padding: 0 0 10px 0;
            font-size: $large-font;
            font-weight: 600;
            color: $text-dark-color;
        }
        & .programSubtitle {
            margin: 0;
            width: 90%;
            font-size: $medium-font;
            font-weight: 400;
            color: $text-dark-color;
        }
        & .programDescription {
            margin: 8px 0 5px;
            width: 100%;
            font-size: $small-font;
            font-weight: 400;
            color: $text-light-color;
        }
    }
    & .Count {
        width: 100%;
        @include flex(row,space-between,center);
        flex-wrap: nowrap;
        & .modules {
           margin: 0 10px 0 0;
        }
        & .modules, & .clients {
            width: 50%;
            padding: 15px 0 15px 20px;
            border: 1px solid $border-color;
            @include flex(column,flex-start,flex-start);
            & .modulesTitle, & .clientsTitle {
                font-size: $small-font;
                font-weight: 400;
                color: $text-light-color;
                padding: 0 0 8px 0;
                margin: 0;
            }
            & .modulesCount, & .clientsCount {
                font-size: $large-font;
                font-weight: 600;
                color: $text-dark-color;
                padding: 0;
                margin: 0;
            }
        } 
    }
    & .modulesHeader {
        @include flex(row,space-between,center);
        margin: 50px 0 15px 0;
        & .modulesHeading {
            font-size: $medium-font;
            font-weight: 400;
            color: $text-dark-color;
        }
        & .addModulesButton {
            text-decoration: none;
            background-color: $hover-color;
            color: $primary-color;
            font-size: $small-font;
            font-weight: 500;
            padding: 10px 20px;
            border-radius: $button-borderradius;
        }
    }
    & .modules_container{
        width:inherit;
        @include flex(column,flex-start,center);
        margin: 0 0 20px 0;
    }
}