@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.addProgramContainer {
    margin: 0 auto;
    width: fit-content;
    @include flex(column,flex-start,flex-start);
    & .addProgramContent {
        @include flex(row,center,center);
        margin: 0 auto 0;
    }

}
.next-btn {
    text-decoration: none;
    color: $white-color;
    background-color: $primary-color;
    padding: 6px 12px;
    border: $button-border;
    border-radius: $button-borderradius;
    margin-left: auto;
}