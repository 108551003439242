@import './../../styles/mixinflex';
@import './../../styles/variables';

.filter-container {
    margin: 0 0 0 0;
    
    & .filter {
        padding: 5px;
        color: $text-light-color;
        border: 1px solid $border-color;
        border-radius: $button-borderradius;
        margin: $button-margin;
        background-color: $white-color;
        font-size: $small-font;
        cursor: pointer;
        @include flex(row,center,center);
        
        & .filter-image {
            float: left;
            width: $image-width;
            height: $image-height;
            padding: $image-padding;
        }
    }

    .select-content {
        @include dropdown;
    }
}

.filter-tags {
    max-width: 400px;
    width: fit-content;
    @include flex(row,center,center);
    user-select: none;
    .filter-selected {
        background-color: $nav-background;
        margin: 3px;
        padding:5px 10px;
        border-radius: $button-borderradius;
        color: $primary-color;
        font-size: $small-font;
        font-weight: $font-weight-medium;
        font-family: $font-family;
        @include flex(row,center,center);
        & svg{
            margin:0 0 0 8px;
            cursor:pointer;
         }

    }
}