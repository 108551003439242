@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.assistant_modal_container {
    padding: 0 !important;
    @include formStyles;
    width:350px;
    & .visiting_schedule {
        margin:0 0 10px 0;
        & .visitingSchedule_title {
            margin: 0 0 15px 0;
            font-size: $medium-font;
            font-weight:$font-weight-medium;
        }
        & .scheduled_time {
            width: 100%;
            height: 100px;
            border: 1px solid $border-color;
            overflow-y: auto;
            margin: 15px 0;
            padding:5px;
            & .schedule_component{
                margin:5px 0;
                cursor: pointer;
                width:fit-content;
                padding:5px;
                @include flex(row,center,center);
                border:1px solid $border-color;
                border-radius:2px;
                & .schedule_day{
                    font-size:$tiny-font;
                }
                & .schedule_time{
                    margin:0 0 0 8px;
                    font-size: $tiny-font;
                    color:$text-light-color;
                }
                & svg{
                    margin:0 0 0 8px;
                    &:hover{
                        background-color: $hover-color;
                    }
                }
                &:hover{
                    border-color:$primary-color;
                }
            }
        }
        & .visiting_day_and_time {
            @include flex(column,center,center);
            flex-wrap: nowrap;
            & .visiting_day {
                width: 100%; 
                margin: 0 0 15px 0;
            }
            & .time_schedular{
                width:100%;
                @include flex(row,space-between,center);
                & .time_schedular_container{
                    width:45%;
                    margin:0;
                    & .start_time_div, & .end_time_div {
                        @include flex(row,space-between,center);
                        width:100%;
                        & select:first-child {
                            width:100%;
                            flex:1;
                        }
                        & select:last-child {
                            width:100%;
                            flex:1;
                            margin:0 0 0 8px;
                        }
                    }
                }
               
            } 
        }  
    }     
}