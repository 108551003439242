@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.textEditorContainer {
    width: 750px;
    padding: 15px 25px;
    background-color: $white-color;
    border-radius: $card-borderradius;
    border: 1px solid $border-color;
    padding: 25px;
    position: relative;
    & .toolBar {
        display: flex;
        justify-content: flex-end;
        & .maximize_btn {
            margin: 0 30px 0 0;
        }
        & .delete_btn, & .maximize_btn {
            width: $image-width;
            height: $image-height;
            margin: 0 0 15px 0;
            padding: 8px;
            cursor: pointer;
            &:hover {
                background-color: $hover-color;
                border-radius: 50px;
            }
        }
    }
}