@import './../../styles/mixinflex';
@import './../../styles/variables';

.callToActionBtn {
    border: $button-border;
    border-radius: $button-borderradius;
    padding: 6px 12px;
    height:35px;
    font-size: $small-font;
    font-weight: $font-weight-regular;
    @include flex(row,center,center); 
    &:hover{
        box-shadow: none;
    }
}
.primary_btn {
    background-color: $primary-color;
    font-size: $medium-font;
    color: $white-color;
    padding: 6px 12px;
    &:hover{
        opacity:.9;
    }
}
.secondary_btn {
    background-color: $hover-color;
    color: $primary-color;
}
.tertiary_btn {
    background-color: $white-color;
    color: $primary-color;
    border:1px solid $white-color;
    &:hover{
        background-color: $nav-background;
    }
}
.icon_btn{
    @include flex(row,center,center);
    border-radius: 50px;
    padding: 0;
    width:35px;
    background-color: $white-color;
    &:hover{
        background-color: $nav-background;
        & svg{
            & line{
                stroke:$primary-color;
            }    
        }
    }
}
.fluid{
    width:100%;
}

.small{
    padding:6px;
}

.regular{
    font-size: 16px;
}

.medium{
    font-weight:600;
}

.disable{
    cursor: not-allowed;
    background-color: #ccc;
}

.buttonBottomStyle {
    margin: 20px 0 0 0 !important;
}

@media screen and (max-width:350px){
    .primary_btn{
        font-size: $small-font !important;
    }
}

@media screen and (max-width:425px) {
    .buttonBottomStyle {
        width: 90%;
    }
}
