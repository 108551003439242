@import './../../../../styles/mixinflex';
@import './../../../../styles/variables';

.information_sidebar {
    width: 186px;
    height:100%;
    border: 1px solid $border-color;
    .nav-items {
        padding: 0;
        margin: 0;
        list-style-type: none;
        width: 100%;
        @include flex(column,flex-start,flex-start);

        .nav-item {
            margin: 0;
            width:inherit;
            padding: 15px 10px;
            font-family: $font-family;
            text-decoration: none;
            cursor: pointer;
            font-size: $small-font;
            font-weight: $font-weight-medium;
            color:$text-light-color;
            border-bottom: 1px solid $border-color;
            &:hover  {
                color: $primary-color;
            }
        }
        .active-item {
            color: $primary-color;           
        }
    }
    
}
