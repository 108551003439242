@import './../../styles/mixinflex';
@import './../../styles/variables';

.module{
    width:inherit;
    border: 1px solid $border-color;
    padding:25px 0;
    @include flex(column,flex-start,center);
   & .module_content{
    @include flex(row,space-between,center);
    width:inherit;
    & .module_name{
        margin:0 0 0 25px;
        flex:1;
        font-size:$medium-font;
        font-weight: $font-weight-medium;
    }
    & .module_option{
        & > *:last-child{
            margin: 0 30px 0 25px;
        }
        & .preview_btn,& .edit_module {
            color:$text-light-color;
            &:hover{
                color:$primary-color
            }
        }
    }
    & .accordation_icon{
        margin:0 25px 0 0;
        cursor: pointer;
    }
   }
   & .module_description{
       padding:15px 25px 0 25px;
       font-size: $tiny-font;
       color:$text-light-color;
   }
}
