@import './../../styles/mixinflex';
@import './../../styles/variables';

.popup-container {
    @include flex(column,center,flex-start);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    & .form-element {
        padding: 0 0 20px 0;
        @include flex(column,flex-start,space-between);
    }
    & label {
        margin: 0 0 8px 0;
        font-size: $small-font;
        font-weight: $font-weight-regular;
        color: $text-dark-color;
    }

    .inputField {
        padding: 8px;
        width: 300px;
        margin: 5px;
    }
    & select {
        & option {
            color: black;
        }
    }
    & #f-type {
        width: 320px;
    }
}