@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.patientRow {
    width: 95%;
    margin: 0;
    cursor: pointer;
    & .patientRowDetails {
        border: 1px solid $border-color;
        border-radius:  $card-borderradius;
        padding: 25px 30px;
        margin: 0 0 25px 0;
        @include flex(column,flex-start,center);
        & .rowDetails1 {
            width: 100%;
            @include flex(row,space-between,center);
            & .patientName {
                font-size: $large-font;
                font-weight: $font-weight-semibold;
                color: $text-dark-color;
                margin: 0;
            }
            & .patientStatus {
                font-size: $small-font;
                font-weight: $font-weight-medium;
                color: $primary-color;
                background-color: $hover-color;
                border-radius: $statusBtn-borderradius;
                padding: 4px 8px;
                margin: 0;
                @include flex(row,flex-start,center);
                & .activeDot {
                    width: 8px;
                    height: 8px;
                    background-color: $primary-color;
                    border-radius: $statusBtn-borderradius;
                    margin: 0 5px 0 0;
                }
            }
        }
        & .rowDetails2 {
            width: 100%;
            display: flex;
            // @include flex(row,flex-start,flex-start);
            flex-wrap: nowrap;
            padding: 15px 0 0 0;
            & .doctorDetails {
                padding: 0 50px 0 0;
            }
            & .cohortDetails , & .doctorDetails {
                @include flex(column,flex-start,center);
                & .cohortTitle, & .doctorTitle {
                    font-size: $small-font;
                    font-weight: $font-weight-regular;
                    color: $text-light-color;
                    margin: 0;
                    padding: 0 15px 0 0;
                }
                & .cohortStartDate, & .doctorName {
                    font-size: $medium-font;
                    font-weight: $font-weight-regular;
                    color: $text-dark-color;
                    margin: 0;
                }
            }
        }
    }
}