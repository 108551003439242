@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.patients_page {
    & .patients_page_content {
        & .patient_row_component {
            & .noDataDiv {
                & .noDtaContent {
                    @include noData;
                }
            }
        }
    }
}