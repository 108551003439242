@import './../../../../styles/mixinflex'; 
@import './../../../../styles/variables';

.clinicAssociateCard{
    width:100%;
    cursor: pointer;
    border: 1px solid $border-color;
    border-radius:  $card-borderradius;
    padding: 20px;
    & .clinicAssociateCardDetails{
        & .rowDetails1{
            h1{
                font-size: $medium-font-2;
                font-weight: $font-weight-semibold;
                color: $text-dark-color;
                margin: 0;
            }
        }
        & .rowDetails2{
            width:100%;
            margin:8px 0 0 0;
            display: flex;
            flex-wrap: nowrap;
            & .data_content{
                @include flex(column,space-between,center);
                width:100%;
                & .associate_key{
                    font-size: $small-font;
                    font-weight: $font-weight-regular;
                    color: $text-light-color;
                    margin: 0 15px 0 0;
                }
                & .associate_value{
                    font-size: $medium-font;
                    font-weight: $font-weight-regular;
                    color: $text-dark-color;
                    margin: 0;
                }
            }
        }
    }
    &:not(:last-child){
        margin:0 0 10px 0;
    }
}