@import './../../styles/mixinflex';
@import './../../styles/variables';

.doctor_and_Caremanager_Patient_profile_container {
    position:relative;
    border: 1px solid $border-color;
    padding: 20px;
    .patient_heading {
        font-size: $small-font;
        font-weight: $font-weight-regular;
        color: $text-light-color;
        margin: 0;
    }
    .patient_value {
        font-size: $small-font;
        font-weight: $font-weight-regular;
        color: $text-dark-color;
        padding: 5px 0 0 0;
    }
    .patient_age, .patient_gender, .patient_doctor, .patient_assistant_care_manager, .patient_relationship_status, .patient_cohort_start_date {
        @include flex(column,flex-start,flex-start);
    }
    .patient_age_and_gender_container, .patient_doctor, .patient_assistant_care_manager, .patient_relationship_status {
        padding: 0 0 24px 0;
    }
    & .patient_profile_header {
        padding: 0 0 13px 0;
        @include flex(row,space-between,center);
        & .patient_name {
            font-size: $large-font;
            font-weight: $font-weight-semibold;
            color: $text-dark-color;
            margin: 0;
        }
        & .patient_profile_header_group {
            display: flex;
            & .patient_status {
                font-size: $small-font;
                font-weight: $font-weight-medium;
                color: $text-success;
                background-color: $bg-success;
                border-radius: $statusBtn-borderradius;
                padding: 2px 8px;
                margin: 0 0 0 20px;
                @include flex(row,flex-start,center);
                & .activeDot {
                    width: 8px;
                    height: 8px;
                    background-color: $text-success;
                    border-radius: $statusBtn-borderradius;
                    margin: 0 5px 0 0;
                }
                & .newDot {
                    width: 8px;
                    height: 8px;
                    background-color: $primary-color;
                    border-radius: $statusBtn-borderradius;
                    margin: 0 5px 0 0;
                }
            }
        }
        & .New {
            color: $primary-color;
            background-color: $hover-color;
        
        }
    }
    & .patient_age_and_gender_container {
        @include flex(row,flex-start,center);
        & .patient_age {
            padding: 0 30px 0 0;
        }
    }
    & .patient_assistant_care_manager, .patient_doctor {
        & .patient_value {
            @include flex(row,flex-start,center);
            & .patient_message {
                padding: 0 0 0 30px;
            }
        }
    }
    & .patient_cohort_start_date {
        margin: 0 0 30px 0;
    }
}
// .update_patient_profile_form {
//     width: 70%;
// }
.doctor_payment_options_container{
    width:350px;
    & .patient_payment_container{
        border: 1px solid $border-color;
        padding:10px;
        margin:10px 0;
        font-size: $medium-font;
        @include flex(row, space-between, center);
        & .patient_payment_block{
          width:100%;
          @include flex(row, space-between, center);
          & .patient_payment_content{
            @include flex(row, flex-start, center);
            & .patient_pays{
              color:$text-light-color
            }
            & .patient_pays_amount{
              margin: 0 0 0 5px;
              color:$text-dark-color;
              font-weight:$font-weight-bold;
            }
          }
        }
        & .discount_input_container{
          margin:2px 0 8px;
          width:100%;
          @include flex(row, flex-start, flex-end);
            & .discount_input{
              width:90px;
              & label{
                display:block;
                font-size:$small-font;
                color:$text-light-color;
                margin:0 0 2px 0;
              }
              & input{
                width:100% ;
                font-size:$medium-font;
                padding:10px 0 10px 8px;
                border: 1px solid $border-color;
                border-radius: $button-borderradius;
              }
            }
            & button{
              width:10%;
              width:fit-content;
              margin:0 0 0 20px;
              height:40px;
            }
        }
      }
    &  .payment_option_container{
        margin:10px 0 0 0;
        & .payment_option_title {
          font-size: $medium-font;
          font-weight: $font-weight-medium;
          margin: 0 0 10px 0;
        }
        & .radioButtonGroup{
            width:60%;
            margin:0 0 10px 0;
            @include flex(row,flex-start,center);
            flex-wrap: nowrap;
            & label{
                width:100%;
                border:1px solid $border-color;
                cursor:pointer;
                padding:10px;
                margin:0;
                & input[type='radio']{
                    cursor:pointer;
                    width:fit-content;
                    margin:0 10px 0 0;
                }
                &:hover{
                    background-color:$hover-color;
                }
            }
           
        }
    }
    & .continue_btn{
        @include flex(row,flex-end,center);
    }
    & .qr_container{
        @include flex(column,flex-start,center);
        & .header{
            @include flex(row,space-between,center);
            width:100%;
        }
        & .qr_code{
            margin:15px auto;
        }
        & .qr_content{
            margin:0 auto;
        }
    }
  
}
@media screen and (max-width:500px){
    .doctor_payment_options_container{
       width: 100%;
        &  .form-element{
            & h3{
                font-size: $large-font;
                font-weight:$font-weight-semibold;
                margin:0 0 15px 0;   
            }
            & .radioButtonGroup{
                width:100%;
            }
        }
        & .continue_btn{
            margin:20px 0 0 0;
            @include flex(row,flex-end,center);
            & >*{
                width:100%;
            }
        }
    }
}