@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.information_container {
    @include flex(row,flex-start,flex-start);
    flex-wrap: nowrap;
    height:100%;
    & .information_container_mobile{
        width:100%;
        @include flex(column,center,center);
        & .form-element{
            align-self: flex-start;
            margin: 0 0 0 10px;
            & select {
                background-color: $white-color;
                border: 1px solid $border-color;
                border-radius: 2px;
                padding: 10px;
                margin: 0;
                width: 100%;
                font-family: inherit;
                font-size: inherit;
                cursor: pointer;
                line-height: inherit;
            }   
        }
        & .information_content_mobile{
            margin:20px 0 0 0;
        }
    }
}