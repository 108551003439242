@import './../../../styles/mixinflex';
@import './../../../styles//variables';

.updatePatientContainer {
    @include formStyles;
    width: 600px;
    height: 80vh;
    .updatePatientheader {
        @include flex(row,space-between,flex-start);
    }
    .updatePatientForm {
        //width: inherit;
        @include flex(column,flex-start,center);
        .updatePatientDetails {
            width: 100%;
            @include flex(row,space-between,flex-start);
            & .patientDetails1, & .patientDetails2 {
                width: 45%;
            }
        }
        & .updatePatientBtn {
            margin: 0 0 20px 0;
        }
    }
}