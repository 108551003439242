@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.profile-section {


    & .doctor-profile, & .caremanager-profile, & .patient-profile {
        @include flex(row,space-evenly,center);
        border: 1px solid $border-color;
        border-radius: $card-borderradius;
        padding: 15px;

        & .profile-pic {
            width:100px;
            height: 100px;
            & img {
                object-fit: contain;
            }
        }
        & .profile-info {
            max-width: 190px;
            margin: 0 0 0 20px;
            line-height: 1.3rem;
            & .name_and_status_group {
                width: 100%;
                @include flex(row,space-between,center);
            }
            & .name {
                font-size: $medium-font;
                color: $text-dark-color;
                font-weight: $font-weight-semibold;
                padding: 0 0 2px 0;
            }
            & .practice, & .clinic, & .contact, & .type, & .gender, & .diagnosis, & .doctor-name, & .program-referred, & .relationship-status {
                font-size: $small-font;
                font-weight: $font-weight-regular;
                color: $text-light-color;
                padding: 0 0 10px 0;
            }
            & .diagnosis, & .program-referred, & .relationship-status, & .doctor-name {
                padding: 0 0 3px 0;
            }
            & .clinic, & .contact {
                color: $text-dark-color;
                padding: 0 0 4px 0;
            }
            & .contact {
                font-weight: $font-weight-medium;
                padding: 0;
            }

            & a {
                text-decoration: underline;
            }
        }
    }

    & .patient-profile {
        display: block;

        & .profile-info {
            margin: 0;
        }
    }

}