@import './../../styles/mixinflex';
@import './../../styles/variables';

.form_container {
    max-width: 450px;
    width:100%;
    border: 1px solid $border-color;
    margin: 20px auto;
    & .form_content{
        display:flex;
        margin:20px;
        flex-flow:column nowrap;
        & .divider,& .logout{
            align-self: center;
        }
        & .logout{
            margin: 10px 0;
        }
        @media (max-width:480px) {
            & .divider{
                margin:0 0 40px 0;
            }           
        }
    }
    & .form_content{
        & .backButton{
            margin: 10px 0 0 10px;
            @include flex(row,flex-start,flex-start);
        }
        & .login-logo {
            width: 55px;
            margin:0 auto 15px;
            & .logo_image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        & .container {
            @include formStyles;
            padding: 0 !important;
        }  
        & .field-error {
            display: none;
            font-size: $small-font;
            align-self: flex-start;
            position: relative;
            color: #ff7a7a;
            padding: 8px 0 0 0;        
        }
        
    }


}

@media screen and (max-width: 480px) {

    .form_container {
        border: none;
        & .backButton{
            padding:0;
            margin:0 ;
        }
    }
    
}


// .profileContainer {
    
//     & .profileHeader {
//         padding: 15px;
//         @include flex(row,space-between,center);
//         & .edit-btn {
//             padding: 4px 6px;
//             border: $button-border;
//             border-radius: $button-borderradius;
//             @include flex(row,flex-start,center);
//             flex-wrap: nowrap;
//             & .edit-image {
//                 padding: 0 10px 0 0;
//                 width: $image-width;
//                 height: $image-height;
//             }
//         }
//     }
//     & .profileItems {
//         padding: 50px 0 0 55px;
//         &:last-child {
//             padding-bottom: 50px;
//         }
//         & .itemLabel {
//             font-size: $small-font;
//             padding: 0 0 10px 0;
//             text-transform: capitalize;
//         }
//         & .itemValue {
//             font-size: $medium-font;
//             padding: 0;
//             margin: 0;
//         }
//     }
// }