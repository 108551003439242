@import './../../styles/mixinflex';
@import './../../styles/variables';

.success-msg {
    background-color: #C5EBD4;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    color: #184B21;
}

.error-msg {
    background-color: #FFCCCC;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    color: #740D0A;
}

.warning-msg {
    background-color: #fffecc;
    margin: auto;
    max-width: 400px;
    padding: 20px;
    color: #72740a;
}

@keyframes dots-1 { from { opacity: 0; } 25% { opacity: 1; } }
@keyframes dots-2 { from { opacity: 0; } 50% { opacity: 1; } }
@keyframes dots-3 { from { opacity: 0; } 75% { opacity: 1; } }
@-webkit-keyframes dots-1 { from { opacity: 0; } 25% { opacity: 1; } }
@-webkit-keyframes dots-2 { from { opacity: 0; } 50% { opacity: 1; } }
@-webkit-keyframes dots-3 { from { opacity: 0; } 75% { opacity: 1; } }

.dots span {
    animation: dots-1 1s infinite steps(1);
    -webkit-animation: dots-1 1s infinite steps(1);
}

.dots span:first-child + span {
    animation-name: dots-2;
    -webkit-animation-name: dots-2;
}

.dots span:first-child + span + span {
    animation-name: dots-3;
    -webkit-animation-name: dots-3;
}