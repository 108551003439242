@import './../../../styles/mixinflex';
@import './../../../styles/variables';

.section {
    text-align: left;
    margin: 15px 0;
}
.support-staff {
    padding: 20px 25px;
    border: 1px solid $border-color;
    border-radius:  $card-borderradius;
    
    & .title-section {
       @include flex(row,space-between,center);
       
        & .title {
           font-size: $small-font;
           font-weight: $font-weight-medium;
           color: $text-dark-color;
        }
        & svg {
            cursor: pointer;
            &:hover  {
                color: $primary-color;
                & svg{
                    & path{
                        stroke:$primary-color
                    }   
                }

            }
        }
    }
    .staff-list {
        margin: 20px 0;
        
        .item {
            margin-bottom: 15px;
            padding: 15px;
            border: 1px solid $border-color;
            border-radius:  $card-borderradius; 
            @include flex(column,flex-start,flex-start);
            .field {
                @include flex(column,flex-start,flex-start);
                width:100%;
                line-height: 1.5;
                & .name_status_group{
                    width: inherit;
                    @include flex(row,space-between,flex-start);
                }
                & .name-value {
                    font-size: $medium-font;
                    font-weight: $font-weight-semibold;
                    color: $text-dark-color;
                    padding: 0 0 10px 0;
                }
                & .clinic-value, & .phoneNumber-value {
                    font-size: $small-font;
                    font-weight: $font-weight-regular;
                    color: $text-light-color;
                    padding: 0 0 4px 0;
                }
                & .phoneNumber-value {
                    font-weight: $font-weight-medium;
                    color: $text-dark-color;
                    padding: 0 0 14px 0;
                }

                .field-label {
                    margin-left: 50px;
                    text-align: left;
                }
    
                & .field-value {
                    font-weight: $font-weight-regular;
                    text-align: left;
                }
                
                & button {
                    width: 100px;
                    height: 30px;
                    font-size: $small-font;
                }

                & .revoked {
                    @include revoked;
                }

                & .pending {
                    @include pending;
                }

                & .approved {
                    @include approved;
                }

            }

            & .btn-wrap {
                text-align: right;
                margin-top: 0px;

                button {
                    padding: 5px 10px;
                    font-size: $small-font;
                    border-radius: $button-borderradius;
                }

                & .revoke-btn {
                    color: $button-danger-primary;
                    background-color: $white-color;
                    border: 1px solid $button-danger-primary;
                    &:hover {
                        background-color: $button-danger-primary;
                        color: $white-color;
                    }
                }
    
                & .approve-btn {
                    color: $button-success-primary;
                    background-color: $white-color;
                    border: 1px solid $button-success-primary;
                    &:hover {
                        background-color: $button-success-primary;
                        color: $white-color;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}